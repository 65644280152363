@import '../../assets/scss/_shared.scss';

$appBannerWidth: 562px;
$appBannerRight: 150px;
$appBannerRightMob: 250px;

.hero-banner {
	// @extend %container;
	// max-width: 1440px;
	position: relative;
	height: 600px;
	width: 100%;
	overflow: visible;
	background-size: auto 100%;

	@media (max-width: 1440px) {
		// background-size: contain;
		background-position: calc(100% + 128px) 0;
	}
	&.loggedIn {
		background: url('../../img/home/hero/hero-banner-img-login.jpg') no-repeat right 0;
		background-size: auto;
		@media (min-width: 1500px) {
			background: url('../../img/home/hero/hero-banner-img-login@2x.jpg') no-repeat right 0;
			background-size: 60%;
		}
		//@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
		//	background-image: url('../../img/home/hero/hero-banner-img-login@2x.jpg');
		//}
	}
	@media (max-width: 875px) {
		min-height: 70vh;
		background: linear-gradient(311.51deg, rgba(0, 125, 222, 1) 0%, rgba(0, 71, 186, 1) 100%);
		background-size: cover !important;
		padding: 50px 30px;
		display: flex;
		align-items: center;

		&.loggedIn {
			background: linear-gradient(311.51deg, rgba(0, 125, 222, 1) 0%, rgba(0, 71, 186, 1) 100%);
		}
	}
	.oval-holder {
		height: 600px;
		position: absolute;
		// max-width: 790px;
		width: 55%;
		top: 0;
		left: 0;
		background: url('../../img/hero-banner-bg.svg') no-repeat;
		background-position: right top;
		background-size: 100% auto;

		@media (max-width: 1440px) {
			background-size: auto;
		}

		@media (max-width: 875px) {
			display: none;
		}
	}

	.info-holder {
		@extend %container;
		max-width: 1440px;
		padding: 0 40px;
		@extend %v-align;
		left: 0;
		right: 0;

		@media (max-width: 875px) {
			position: static;
			padding: 0;
			margin: 0;
			transform: translateY(0);
		}
	}

	.mouse-icon {
		background: linear-gradient(360deg, rgba(0, 125, 222, 1) 0%, rgba(1, 107, 210, 1) 100%);
		position: absolute;
		bottom: -27px;
		right: 31px;
		border-radius: 50%;
		width: 80px;
		height: 80px;
		@media (max-width: 875px) {
			display: none;
		}
	}
	.mouse-icon a {
		@extend %full-align;
		display: inline-block;
		width: 65px;
		height: 65px;
		background: url('../../img/mouse-icon.svg') no-repeat;
		& span {
			@extend %h-align;
			position: relative;
			white-space: nowrap;
			color: #ffffff;
			font-family: Arial;
			font-size: 13px;
			bottom: 24px;
			left: 0;
		}
	}
	& h1 {
		@extend %esso-title;
		margin-top: 0;
		color: #fff;

		@media (max-width: 875px) {
			font-size: 34px;
		}
	}
	& p {
		@extend %esso-text;

		&.hero-puntens {
			font-family: $EMSemi;
		}

		&.hero-terms {
			color: rgba(255, 255, 255, 0.5);
			font-style: italic;
			font-size: 11px;

			a {
				color: rgba(255, 255, 255, 0.5);
				text-decoration: underline;
			}
		}
	}

	.not-logged-info {
		max-width: 400px;

		@media (max-width: 450px) {
			p br {
				display: none;
			}
		}
		@media (max-width: 400px) {
			img {
				height: 36px;
			}
		}
		& .app-download-icons {
			margin-top: 35px;
			display: flex;
			a:first-child {
				margin-right: 12px;
			}
		}
	}

	.logged-info {
		max-width: 400px;
		@media (max-width: 450px) {
			p br {
				display: none;
			}
		}
		@media (max-width: 400px) {
			img {
				height: 36px;
			}
		}
		& .app-buttons {
			margin-top: 50px;
			& a {
				@extend %white-btn;
				position: relative;
				margin-right: 15px;
				background-repeat: no-repeat;
				background-position: 12px center;
				@media (max-width: 400px) {
					margin-right: 10px;
				}
				&:first-child {
					color: #fff;
					background-image: url('../../img/checkmark-round.svg');
				}
				&:last-child {
					color: #003399;
					background-color: #fff;
					background-image: url('../../img/play-round.svg');
				}
			}
		}
	}

	.logged-info-additional {
		.app-download-icons {
			text-align: left;
			margin-top: 20px;
		}
		.app-info_wrapper {
			margin-top: 25px;
		}
		a.link-btn {
			@extend %blue-btn;
			margin-top: 20px;
		}
	}

	.esso-card {
		@extend %full-align;
		width: 345px;
		border-radius: 10px;
		background-color: rgba(255, 255, 255, 0.97);
		box-shadow: 0 5px 14px 0 rgba(0, 0, 0, 0.1);
		padding: 25px;
		@media (max-width: 1290px) {
			left: 70%;
		}
		@media (max-width: 875px) {
			display: none;
		}
		& .esso-logo-2d {
			top: 25px;
			right: 25px;
			height: 38px;
			width: 53px;
			background: url('../../img/home/esso-2d.png') no-repeat;
		}
		& .extras-balance {
			color: #002164;
			font-family: Arial;
			font-size: 13px;
			font-weight: bold;
			letter-spacing: 2px;
			line-height: 15px;
			top: 33px;
			left: 25px;
			& .extras-amount {
				display: inline-block;
				margin-top: 15px;
				font-family: $EMSemi;
				font-size: 27px;
			}
		}
		& .detail-name {
			@extend %pale-bold;
			position: absolute;
			top: 3px;
			left: 0;
		}
		& .card-details {
			position: relative;
			top: 0;
			margin-top: 48px;
			padding-left: 90px;
		}
		& .holder-name {
			@extend %pale-bold;
			color: #002164;
			font-size: 13px;
		}
		& .card-number {
			color: #002164;
			font-family: 'OcrA';
			font-size: 15px;
		}
		& .holder-details {
			position: relative;
			top: 0;
			margin-top: 30px;
			padding-left: 90px;
		}
		& .phone-number {
			@extend %pale-bold;
			color: #002164;
			font-size: 13px;
		}
		& .phone-description {
			@extend %pale-bold;
			font-size: 11px;
		}
	}
}

.user-dash {
	@extend %container;
	margin: 50px auto 50px auto;
	display: flex;
	justify-content: space-between;
	@media (max-width: 1000px) {
		justify-content: center;
	}
	@media (max-width: 767px) {
		flex-wrap: wrap;
	}
	.block-item {
		max-width: 590px;
		width: 100%;
		margin: 0 15px;

		> div:not(.block-heading) {
			background: #fff;
			padding: 27px;

			@media (max-width: 767px) {
				margin: 0 auto 30px;
				width: 100%;
			}
		}
		&:first-child {
			order: 1;
		}

		&.dash-block {
			margin-top: 4px;
			@media (max-width: 1000px) {
				display: none;
			}
		}

		@media (max-width: 767px) {
			margin: 0;
		}
	}
	.block-heading {
		display: flex;
		justify-content: space-between;
		align-items: baseline;

		a {
			color: rgba(0, 51, 153, 0.3);
			font-family: Arial, Helvetica, sans-serif;
			font-size: 12px;
			font-weight: bold;
			letter-spacing: 1.2px;
			text-transform: uppercase;
			display: inline-block;
			vertical-align: middle;
			span {
				height: 19px;
				display: inline-block;
				vertical-align: middle;
				padding-left: 10px;
			}
			&:hover {
				color: rgba(0, 51, 153, 0.9);
			}
			.menu-arrow {
				stroke: #0047ba;
			}
			@media #{$mq-mobile} {
				display: none;
			}
		}
	}

	h3 {
		@extend %bold-title;
		margin-top: 0;
	}

	.frequency-block {
		max-width: 400px;

		@media (max-width: 767px) {
			max-width: 100%;
		}
		+ .transactions-block {
			max-width: 730px;
		}
	}
	.frequency-modal {
		position: static;
		width: 100%;
	}
	.dash-links {
		padding: 30px 40px;

		a {
			@extend %bold-title;
			display: inline-block;
			width: 100%;
			padding: 30px 0 30px 60px;
			background-repeat: no-repeat;
			background-position: 0 center;
			&:not(:last-child) {
				border-bottom: 2px solid rgba(223, 234, 255, 0.4);
			}
			&.transactions-link {
				background-image: url('../../img/speedometer.svg');
			}
			&.cards-link {
				background-image: url('../../img/manage.svg');
			}
			&.rewards-link {
				background-image: url('../../img/gift-card.svg');
			}
		}
	}
}

.transactions-list {
	position: relative;
}

/* TransactionInfo Component */
.transaction-info {
	display: flex;
	padding: 15px 0;
	align-items: center;
	@media #{$mq-mobile} {
		justify-content: flex-start;
		align-items: flex-start;
	}
	span {
		display: inline-block;
		&.date-text-wrapper {
			display: flex;
			justify-content: center;
			align-items: center;
			@media #{$mq-mobile} {
				flex-direction: column-reverse;
				align-items: flex-start;
			}
		}
	}
	.transaction-date {
		margin-right: 80px;
		color: #003399;
		font-family: Arial;
		font-size: 13px;
		min-width: 100px;
		display: inline-block;
		@media (max-width: 450px) {
			margin-right: 20px;
		}
	}
	.transaction-text {
		color: #002164;
		font-family: Arial;
		font-size: 13px;
		font-weight: bold;
		line-height: 1.6;
		span {
			@extend %pale-text;
			color: rgba(0, 51, 153, 0.3);
			font-weight: 400;
			font-size: 13px;
			@media #{$mq-mobile} {
				display: none;
			}
		}
		br {
			@media #{$mq-mobile} {
				display: none;
			}
		}
	}
	.transaction-amount {
		font-family: Arial;
		font-size: 16px;
		font-weight: bold;
		margin-left: auto;
		.positive-amount {
			color: #27d9a1;
			@media #{$mq-mobile} {
				color: $textColor;
				&::before {
					content: '+';
				}
			}
		}
		.negative-amount {
			color: #ff2249;
			@media #{$mq-mobile} {
				color: $textColor;
			}
		}
		.tanken-text {
			display: none;
			color: $textColor;
			color: rgba(0, 51, 153, 0.3);
			font-family: Arial;
			font-size: 13px;
			line-height: 15px;
			font-weight: normal;
			position: relative;
			bottom: -6px;
			@media #{$mq-mobile} {
				display: inline-block;
			}
		}
		@media #{$mq-mobile} {
			display: flex;
			flex-direction: column;
			align-items: flex-end;
		}
	}
}

/* TransactionInfo Component END */

.popular-rewards {
	@extend %container;
	margin: 50px auto 50px auto;
	@media (max-width: 1220px) {
		max-width: 950px;
	}

	.rewards-arr {
		display: none;
		padding-left: 15px;
		@media #{$mq-tablet} {
			display: block;
		}
	}

	& .rewards-title {
		@extend %esso-title;
		display: flex;
		@media #{$mq-tablet} {
			margin-left: 30px;
		}
		@media #{$mq-mobile} {
			margin: 22px;
			margin-left: 0;
		}
	}
	& ul {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		flex-wrap: wrap;
		overflow: hidden;
		align-items: center;
		height: 311px;
	}
}
.all-rewards-link {
	display: block;
	margin-left: auto;
	text-transform: uppercase;
	color: rgba(0, 51, 153, 0.3);
	font-family: Arial;
	font-size: 12px;
	font-weight: bold;
	letter-spacing: 1px;
	margin-right: 90px;
	&:hover {
		color: rgba(0, 51, 153, 0.5);
	}
	@media #{$mq-tablet} {
		display: none;
	}
}
.popular-rewards {
	.all-rewards-link {
		display: block;
		margin-left: auto;
		text-transform: uppercase;
		color: rgba(0, 51, 153, 0.3);
		font-family: Arial;
		font-size: 12px;
		font-weight: bold;
		letter-spacing: 1px;
		margin-right: 90px;
		&:hover {
			color: rgba(0, 51, 153, 0.5);
		}
		@media #{$mq-tablet} {
			display: none;
		}
	}
}
.rewards-list-item {
	width: 280px;
	padding: 60px 30px 50px 30px;
	background: #fff;
	text-align: center;
	height: 100%;
	&:focus {
		outline: none;
	}
	.hidden {
		opacity: 0;
	}

	@media (max-width: 1200px) {
		width: 250px;
	}

	.lazyload-wrapper {
		height: 205px;
	}

	& img {
		max-width: 170px;
		max-height: 170px;
		border-radius: 6px;
		margin: 0 auto;
		margin-bottom: 35px;
	}
	& .reward-meta {
		display: flex;
		margin-bottom: 20px;
		align-items: center;
		justify-content: space-between;
		& .reward-type {
			text-transform: uppercase;
			display: inline-block;
			padding: 6px;
			color: #fff;
			font-family: Arial;
			font-size: 10px;
			font-weight: bold;
			border-radius: 5px;
			background-color: #003399;
		}
	}
	& h3 {
		text-align: left;
		color: #002164;
		font-family: Arial;
		font-size: 16px;
		font-weight: bold;
		margin: 10px 0 0 0;
		min-height: 50px;
	}
	& .reward-points {
		display: block;
		color: #003399;
		font-family: Arial;
		font-size: 14px;
		font-weight: bold;
		text-align: left;
		min-height: 44px;
	}
	& p {
		text-align: left;
		color: rgba(0, 51, 153, 0.3);
		font-family: Arial;
		font-size: 13px;
		margin: 5px 0;
	}
}
.esso-card-promo {
	@extend %container;
	margin: 50px auto 50px auto;
	text-align: center;
	& h2 {
		@extend %esso-title;
	}
	& .promo-img {
		position: relative;
		text-align: center;
		padding: 50px 0;
		img {
			max-width: 542px;
			width: 100%;
		}
		& .promo-text {
			@extend %v-align;
			display: inline-block;
			max-width: 300px;
			@media (max-width: 925px) {
				display: none;
			}

			& h3 {
				@extend %bold-title;
				& span {
					@extend %highlight;
				}
			}
			& p {
				@extend %pale-text;
				margin: 20px 0 35px 0;
			}
			& a {
				@extend %link-arrow;
			}
		}
		& .right-promo {
			right: 0;
			text-align: right;
		}
		& .left-promo {
			left: 0;
			text-align: left;
		}
	}
	& .register-card-btn {
		@extend %blue-btn;
	}
	.mobile_promo {
		display: block;
		max-width: 300px;
		display: none;
		text-align: left;
		margin-left: 40px;
		& h3 {
			@extend %bold-title;
			& span {
				@extend %highlight;
			}
		}
		& p {
			@extend %pale-text;
			margin: 20px 0 35px 0;
		}
		& > a {
			@extend %link-arrow;
			display: block;
		}
		& > .register-card-btn {
			float: left;
			margin-left: 40px;
		}
		@media (max-width: 925px) {
			display: block;
		}
	}
}

.app-slider {
	@extend %container;
	margin: 50px auto 50px auto;
	max-width: 1440px;
	height: 623px;
	width: 100%;
	overflow: hidden;
	@media (max-width: 725px) {
		display: none;
	}
	.app-slider-text {
		position: absolute;
		top: 120px;
		left: 250px;
		@media (max-width: 1180px) {
			position: relative;
			left: 50px;
		}
		.slick-track {
			height: 100% !important;
			padding-top: 10px;
			padding-bottom: 10px;
			.slick-slide {
				width: 100%;
				max-width: 100%;
			}
		}
		.app-slider-wrapper {
			max-width: 600px;
			overflow: hidden;

			@media (max-width: 1400px) {
				max-width: 460px;
			}

			@media (max-width: 1270px) {
				max-width: 360px;
			}

			@media (max-width: 1180px) {
				max-width: calc(100% - 50px - #{$appBannerWidth} + #{$appBannerRight});
			}

			@media #{$mq-tablet} {
				max-width: calc(100% - 50px - #{$appBannerWidth} + #{$appBannerRightMob});
			}
		}
	}
	h2 {
		@extend %esso-title;
		font-family: $EMSemi;
		margin-bottom: 40px;
	}
	p {
		@extend %pale-text;
		span {
			color: rgba(0, 33, 100, 1);
			font-family: $EMSemi;
			font-size: 20px;
			display: inline-block;
			vertical-align: middle;
			margin-right: 15px;
		}
	}
	.app-download-icons {
		margin-top: 40px;
		img {
			margin-right: 10px;
		}
	}
	.img-wrapper {
		position: absolute;
		top: 0;
		right: 0;
		background: url('../../img/app-slider-bg.svg') no-repeat;
		height: 623px;
		width: $appBannerWidth;
		@media (max-width: 1180px) {
			right: -#{$appBannerRight};
		}
		@media #{$mq-tablet} {
			right: -#{$appBannerRightMob};
		}
		.iphone {
			position: absolute;
			top: 25px;
			left: 20px;
			background: url('../../img/iphonex.png') no-repeat;
			background-size: contain;
			height: 552px;
			width: 274px;
			overflow: hidden;
			z-index: 1;
			& > img {
				position: relative;
				z-index: 10;
			}

			.iphone-inner {
				height: 100%;
				position: absolute;
			}
			.iphone-inner .slick-track {
				height: 100% !important;
			}
			.slick-track {
				max-height: 530px;
				width: 100% !important;
				img {
					max-width: 240px !important;
					width: 240px !important;
				}
			}
		}
	}
	.slider-bottom {
		margin-top: 40px;
		display: flex;
		align-items: center;

		@media (max-width: 900px) {
			flex-direction: column;
			align-items: flex-start;
			margin-top: 20px;
		}
		.app-download-icons {
			margin-top: 0;
			@media (max-width: 900px) {
				margin-top: 20px;
			}
		}
	}
	.slider-arrows {
		display: flex;
		img {
			cursor: pointer;
			&:first-child {
				margin-right: 20px;
			}
		}
	}
}
.iphone-inner-wrapper {
	top: 40px;
	left: 38px;
	position: relative;
	height: 520px;
	width: 240px;
	overflow: hidden;
	.iphone-inner {
		height: 100%;
	}
}
.esso-extras-program {
	@extend %container;
	margin: 50px auto 50px auto;
	padding: 40px 50px 80px;
	background-color: #fff;
	@media (max-width: 875px) {
		padding: 20px 30px 70px;
		text-align: center;
	}
	& h2 {
		@extend %esso-title;
		// margin-bottom: -20px;
		@media #{$mq-mobile} {
			text-align: left;
		}
	}
	ul {
		text-align: center;
		display: flex;
		position: relative;
		li {
			display: inline-block;
			max-width: 322px;
			text-align: left;
			margin-top: 17px;
			&:not(:last-child) {
				margin-right: 65px;
			}
			@media #{$mq-mobile} {
				max-width: 100%;
			}
			@media (max-width: 875px) {
				margin: 40px 5px 0;
				&:not(:last-child) {
					margin-right: 0;
				}
			}
		}
		@media (max-width: 767px) {
			flex-direction: column;
			align-items: center;
		}
		h3 {
			@extend %bold-title;
			position: relative;
			@extend %highlight;
			font-family: $EMSemi;
			font-weight: 600;
			margin-bottom: 30px;
		}
		p {
			@extend %pale-text;
			word-wrap: break-word;
		}
		a {
			@extend %link-arrow;
			color: #002164;
			position: absolute;
			min-height: 32px;
			bottom: -40px;
			max-width: 27%;
			text-align: left;

			@media (max-width: 767px) {
				position: static;
				max-width: 100%;
			}
		}
		img {
			width: 100%;
		}
	}
}

.programm-icon {
	position: relative;
	width: 30px;
	height: 30px;
	margin-top: 8px;
	margin-bottom: 15px;

	img {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		left: 0;
		max-height: 100%;
		max-width: 100%;
		width: auto;
	}
}

.details-articles {
	@extend %container;
	margin: 50px auto 50px auto;
	padding: 20px 40px;
	background-color: #fff;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	flex-wrap: wrap;
	@media (max-width: 1160px) {
		justify-content: center;
	}
	& .article-content {
		max-width: 520px;
		padding: 20px 0;
		margin: 0 10px;
	}
	& h2 {
		@extend %esso-title;
		margin-top: 35px;
	}
	& p {
		@extend %pale-text;
		max-width: 420px;
	}
	& a {
		@extend %blue-btn;
		margin-top: 20px;
	}
	img {
		width: 100%;
	}
}

.details-full {
	@extend %container;
	margin: 50px auto 50px auto;
	padding: 20px;
	background-color: #fff;
	@media (max-width: 875px) {
		display: none;
	}
	& .article-content {
		display: flex;
		.lazyload-wrapper {
			flex-grow: 1;
			display: flex;
			img {
				max-width: 570px;
			}
		}
	}
	img {
		align-self: flex-end;
		margin-left: auto;
		width: 100%;
		max-width: 520px;
	}
	& .detail-content {
		margin: 20px 20px 0 20px;
		display: inline-block;
		max-width: 450px;
	}
	& h2 {
		@extend %esso-title;
	}
	& p {
		@extend %pale-text;
	}
	& a {
		@extend %blue-btn;
		margin-top: 35px;
	}
}

.about-esso-extras {
	@extend %container;
	margin: 50px auto 50px auto;
	background-color: #fff;
	padding: 40px;
	& ul {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		flex-wrap: wrap;
		margin: 0 -10px;
		@media (max-width: 1000px) {
			justify-content: center;
		}
		& li {
			max-width: 255px;
			margin: 10px;
			@media #{$mq-mobile} {
				max-width: 100%;
			}
		}
	}
	& h2 {
		@extend %esso-title;
		margin: 10px 0 30px 0;
		@media (max-width: 875px) {
			text-align: center;
		}
		@media #{$mq-mobile} {
			text-align: left;
		}
	}
	& img {
		width: 100%;
		margin-bottom: 20px;
		max-height: 180px;
		@media (max-width: 425px) {
			max-height: inherit;
		}
	}
	& h3 {
		@extend %bold-title;
		font-family: $EMRegular;
		& a {
			color: #002164;
			&:hover {
				color: lighten($color: #002164, $amount: 10);
			}
		}
	}
	& p {
		@extend %pale-text;
	}
}

.app-banner {
	@extend %container;
	margin: 50px auto 50px auto;
	// height: 395px;
	background-size: cover;
	background-position: center;
	//&.nl_nl {
	//	background-image: url('../../img/home/banner/app-banner-nl.png');
	//	@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
	//		background-image: url('../../img/home/banner/app-banner-nl@2x.png');
	//	}
	//}
	//&.be_nl {
	//	background-image: url('../../img/home/banner/app-banner-be.png');
	//	@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
	//		background-image: url('../../img/home/banner/app-banner-be@2x.png');
	//	}
	//}
	//&.be_fr {
	//	background-image: url('../../img/home/banner/app-banner-fr.png');
	//	@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
	//		background-image: url('../../img/home/banner/app-banner-fr@2x.png');
	//	}
	//}

	@media (max-width: 1000px) {
		display: none;
	}
	h2 {
		@extend %esso-title;
		color: #fff;
		max-width: 425px;
	}
	p {
		color: #fff;
		font-family: Arial;
		font-size: 16px;
		margin-bottom: 50px;
		max-width: 425px;
	}
	.banner-content {
		// position: absolute;
		padding-top: 35px;
		padding-left: 60px;
		.app-download-icons {
			text-align: left;
		}
	}
	img {
		margin-right: 10px;
	}
}
.iphone-inner {
	.slick-slide {
		margin-right: 0;
	}
}

.slider-control {
	width: 40px;
	cursor: pointer;
	margin-right: 25px;
	&.prev {
		margin-right: 40px;
	}
	.right-arr-next {
		position: relative;
		left: 25px;
		z-index: -1;
	}
	&.disabled {
		i {
			transition: all 0.2s;
			opacity: 0.2;
			&:before {
				transition: all 0.2s;
				width: 32px;
			}
		}
	}
}
.slick-list,
.slick-slider {
	height: 100%;
}

[class*='icono'] {
	position: relative;
	display: inline-block;
	vertical-align: middle;
	color: #003399;
	box-sizing: border-box;
	&:after,
	&:before {
		content: '';
		box-sizing: border-box;
	}
}

[class*='icono-arrow1'] {
	@extend %flex-align;
	width: 9px;
	height: 9px;
	border-width: 2px 2px 0 0;
	border-style: solid;
	margin: 0;
	&:before {
		transition: all 0.2s;
		right: 0;
		top: -2px;
		position: absolute;
		height: 2px;
		box-shadow: inset 0 0 0 32px;
		transform: rotate(-45deg);
		width: 55px;
		transform-origin: right top;
	}
	&[class*='-left'] {
		transform: rotate(45deg);
		&[class*='-up'] {
			transform: none;
		}
		&[class*='-down'] {
			transform: rotate(90deg);
		}
	}
	&[class*='-right'] {
		transform: rotate(-135deg);
		&[class*='-up'] {
			transform: rotate(-90deg);
		}
		&[class*='-down'] {
			transform: rotate(180deg);
		}
	}
	&[class*='-up'] {
		transform: rotate(-45deg);
	}
	&[class*='-down'] {
		transform: rotate(135deg);
	}
}

.last-transactions {
	margin-left: 0;
	box-shadow: 0 7px 13px 0 rgba(0, 0, 0, 0.08);
}
.dash-links {
	margin-right: 0 !important;
	box-shadow: 0 7px 13px 0 rgba(0, 0, 0, 0.08);
}

.hero-puntens {
	font-weight: bold;
}

.details-little-text {
	color: rgba(0, 33, 100, 0.3) !important;
	font-family: Arial !important;
	font-size: 10px !important;
	line-height: 20px;
}
.slider-bottom > .app-download-icons {
	text-align: left;
}

.app-info_wrapper {
	display: inline-block;
	padding: 16px 24px;
	margin-top: 38px;
	border: 1px solid #002164;
	border-radius: 2px;
	color: #002164;
	@media (max-width: 1315px) {
		max-width: 350px;
	}
	&.white {
		border-color: #fff;
		color: #fff;
	}
}
.login-promo {
	display: flex;
	justify-content: flex-end;
	margin-bottom: -36px;

	@media (max-width: $mainWidth + 20px) {
		padding: 0;
	}

	@media (max-width: 875px) {
		position: relative;
		z-index: 3;
	}

	.promo-wrapper {
		position: relative;
		display: flex;
		align-items: center;
		margin-top: -50px;
		width: 45%;
		max-width: 468px;
		padding: 26px 19px 26px 30px;
		border-radius: 8px 0 0 8px;
		background-color: #fff;
		box-shadow: -10px 3px 28px 0 rgba(0, 0, 0, 0.1);

		@media (max-width: 875px) {
			display: block;
			width: 95%;
			max-width: 490px;
			margin-top: -20px;
		}
	}

	.promo-text {
		font: 400 14px/1.458 $EMRegular;
		margin-right: 10px;
		color: #002164;

		@media (max-width: 875px) {
			margin-bottom: 20px;
		}
	}

	.promo-link {
		@extend %blue-btn;
		padding: 16px 49px;
	}
}

//PROMO CAMPAIGN

.promobanners-loader {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	height: 100%;
	z-index: 1;
	background-color: #e9f0f8;

	.mouse-icon {
		bottom: -28px;
		z-index: 2;
		left: calc(55% - 111px);
		background: #d4dfeb;

		@media (max-width: 875px) {
			display: none;
		}
	}

	&:after {
		content: '';
		position: absolute;
		right: 0;
		top: 0;
		bottom: 0;
		width: 60%;
		background: url('../../img/banners-loader-image.png') no-repeat;
		background-size: cover;
		background-position: right center;

		@media (max-width: 1440px) {
			width: 70%;
		}

		@media (max-width: 875px) {
			width: 100%;
			height: 0;
			padding-bottom: 65%;
			bottom: auto;
			background: url('../../img/banners-loader-image-mb.png') no-repeat;
			background-position: center;
		}
	}

	&:before {
		content: '';
		position: absolute;
		z-index: 1;
		left: 0;
		top: 0;
		bottom: 0;
		width: 55%;
		background: url('../../img/banners-loader-oval.svg') no-repeat;
		background-size: 100% auto;
		background-position: right top;

		@media (max-width: 1440px) {
			background-size: auto;
		}

		@media (max-width: 875px) {
			top: calc((100vw * 0.65) - 262px);
			bottom: auto;
			left: -30px;
			width: calc(100% + 60px);
			height: 700px;
			background: url('../../img/banners-loader-ellipse.svg') no-repeat;
			background-size: cover;
		}

		@media (max-width: 640px) {
			top: calc((100vw * 0.65) - 111px);
		}
	}

	@media (max-width: 875px) {
		overflow: hidden;
	}
}

.hero-banner {
	+ .login-promo {
		position: relative;
		z-index: 2;
	}
	&.loaded {
		.promobanners-loader {
			opacity: 0;
			visibility: hidden;
			transition: opacity 0.4s ease-in-out, visibility 0.4s ease-in-out;
		}

		+ .login-promo {
			position: static;
		}
	}
}
.hero-banner.promo-campaign {
	background-image: none;

	+ .login-promo .promo-wrapper {
		z-index: 2;
	}
	.oval-holder {
		z-index: 1;
	}

	.slick-slide {
		width: 100%;
		max-width: 100%;
		margin: 0;
		visibility: hidden;
		z-index: 1;

		&.slick-active {
			visibility: visible;
		}
	}

	.slick-track {
		display: block;
		height: 100%;
		padding: 0;
	}

	.esso-card {
		position: static;
		transform: translate(0, 0);
	}

	@media (max-width: 875px) {
		padding: 0;
		height: auto;
		overflow: hidden;

		.slick-slide,
		.slick-track,
		.slick-list,
		.slick-slider {
			height: auto;
		}

		h1 {
			font-size: 26px;
		}
	}

	.arrows-container {
		margin: 35px -10px 0;
	}

	.prev-arrow,
	.next-arrow {
		display: inline-block;
		vertical-align: middle;
		margin: 0 10px;
		width: 70px;
		height: 14px;
		background: url('../../img/next-arr-white.svg') no-repeat;
		background-size: contain;
		cursor: pointer;
	}

	.prev-arrow {
		transform: rotate(180deg);
	}
}

.promo-banner {
	position: relative;
	height: 100%;
	width: 100%;
	overflow: hidden;

	.promo-image {
		position: absolute;
		right: 0;
		top: 0;
		z-index: 0;
		width: 65%;

		picture {
			position: absolute;
			top: 0;
			right: 0;
			width: 100%;
			height: 100%;

			img {
				height: 100%;
				width: 100%;
				object-fit: cover;
				object-position: center right;
				//ie polyfill
				font-family: 'object-fit: cover; object-position: center right;';
			}
		}

		.slick-list {
			overflow: visible;
		}

		@media (max-width: 875px) {
			position: relative;
			z-index: 0;
			height: 0;
			width: 100%;
			padding-bottom: 65%;
			overflow: hidden;

			picture,
			img {
				height: auto;
			}
		}
	}

	.promo-info-wrapper {
		position: relative;
		z-index: 1;
		width: 50%;
		max-width: 400px;

		@media (max-width: 875px) {
			width: 100%;
			max-width: 100%;
			padding: 0 22px 70px;

			&:after {
				content: '';
				position: absolute;
				top: -260px;
				left: -30px;
				z-index: -1;
				width: calc(100% + 60px);
				height: 700px;
				background: url('../../img/ellipse.svg') no-repeat;
				background-size: cover;
			}
		}

		@media (max-width: 640px) {
			&:after {
				top: -110px;
			}
		}
	}

	.banner-wrapper {
		display: flex;
		align-items: center;
		max-width: 1220px;
		width: 100%;
		height: 100%;
		margin: 0 auto;
		padding: 0 40px;
		background-position: right top;
		background-size: 100% auto;

		@media (max-width: 875px) {
			display: block;
			padding: 0;
		}
	}

	.banner-image {
		position: absolute;
		display: flex;
		justify-content: flex-end;
		z-index: -2;
		right: 0;
		bottom: 0;
		top: 0;
		width: 100%;
		order: 1;
	}

	.banner-info {
		z-index: 1;
		@media (max-width: 875px) {
			position: static;
			transform: translate(0, 0);
			width: 100%;
		}
	}

	.app-download-icons {
		display: flex;
		margin-top: 35px;

		img {
			width: 100%;
		}
	}

	.btn {
		@extend %blue-btn;
		margin-top: 8px;
	}
}

.app-download-icons {
	img {
		width: auto;
	}
}

//LOYALTY TUTORIAL
.loyalty-tutorial {
	@extend %container;
	padding: calc(9% + 66px) 0 13%;
	position: relative;

	@media (max-width: 1220px) {
		padding: calc(9% + 66px) 40px 13%;
	}
	@media #{$mq-tablet} {
		padding: 17% 20px 20px;
	}
	.tutorial-subtitle {
		padding-bottom: 15px;
		font: 400 18px/1.2 $EMRegular;
		color: $textColor;
		margin: 0;
	}
	.tutorial-title {
		padding-bottom: 30px;
		font: 400 36px/1.3 $EMRegular;
		color: $textColor;
		max-width: 400px;
		margin: 0;
	}
	.tutorial-info {
		width: 460px;
		@media (max-width: 1220px) {
			max-width: 50%;
		}

		@media #{$mq-tablet} {
			width: 100%;
			max-width: 100%;
		}
	}

	.accordion__item {
		flex: none;
		background: transparent;

		.accordion_button {
			font: 400 18px/1.2 $EMSemi;
			color: $textColor;
			cursor: pointer;
			opacity: 0.5;
			transition: opacity 0.2s;

			@media #{$mq-tablet} {
				padding-bottom: 30px;
				border-bottom: 1px solid rgba(0, 33, 100, 0.1);
			}

			&[aria-expanded='true'] {
				opacity: 1;
				.accordion-item__num {
					&:after {
						opacity: 1;
					}
				}
				.accordion-item__icon {
					&:after {
						content: '-';
					}
				}
				@media #{$mq-tablet} {
					padding-bottom: 0;
					border-bottom-color: transparent;
				}
			}
		}
		.accordion__panel {
			padding: 0;
		}

		.accordion_button {
			&:focus {
				outline: none;
			}
		}
	}

	.accordion-item__num,
	.accordion-item__icon,
	.accordion-item__title {
		display: inline-block;
		vertical-align: middle;
	}
	.accordion-item__title {
		@media #{$mq-tablet} {
			max-width: calc(100% - 75px);
		}
	}
	.accordion-item__num {
		position: relative;
		margin-right: 20px;
		&:after {
			content: '';
			position: absolute;
			bottom: -6px;
			left: 0;
			width: 25px;
			height: 2px;
			background-color: #003399;
			opacity: 0;
		}

		@media #{$mq-tablet} {
			width: 100%;
			margin-bottom: 10px;
		}
	}

	.accordion-item__icon {
		width: 16px;
		height: 16px;
		position: relative;
		margin-left: 15px;
		margin-top: 3px;
		border-radius: 50%;
		border: 1px solid $textColor;

		&:after {
			content: '+';
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			font: 400 11px $EMSemi;
		}
	}
	.accordion-item__text {
		@extend %esso-text;
		color: rgba(0, 33, 100, 0.5);
		padding-top: 16px;
	}
	.accordion-item__image {
		position: absolute;
		top: 50%;
		right: -57px;
		transform: translateY(-50%);
		max-width: 50%;

		@media (max-width: 1220px) {
			right: -30px;
		}

		@media #{$mq-tablet} {
			position: static;
			width: 100%;
			max-width: none;
			transform: translate(0);
			margin-top: 20px;
		}

		img {
			width: 100%;
			@media #{$mq-tablet} {
				width: auto;
				max-width: 100%;
				height: auto;
			}
		}
	}
}

//HOME BANNERS
.home-banners {
	position: relative;
	height: 0;
	padding-bottom: 23%;
	overflow: hidden;
	@extend %container;
	margin-bottom: 100px;
	color: #fff;

	@media (max-width: 1220px) {
		padding-bottom: 30%;
	}

	@media (max-width: 767px) {
		padding-bottom: 103%;
	}

	img {
		@extend %v-align;
		left: 0;
		width: 100%;
	}

	.banner-info {
		@extend %v-align;
		@extend %container;
		margin: 0;
		width: 400px;
		left: 60px;
		right: 0;

		@media #{$mq-desktop-m} {
			width: 300px;
		}

		@media #{$mq-mobile} {
			left: 0;
			width: 250px;
		}
	}

	.banner-title {
		max-width: 470px;
		margin: 0;
		padding-bottom: 13px;
		font: 400 36px/1.2 $EMSemi;
		color: white;

		@media #{$mq-tablet} {
			font-size: 24px;
		}
	}

	.banner-text {
		margin: 0;
		padding-bottom: 20px;
		font: 400 12px/1.2 Arial;
	}

	.banner-btn {
		@extend %blue-btn;
		background-color: #fff;
		border-color: #fff;
		color: $textColor;

		&:hover {
			color: #fff;
			border-color: #003399;
		}
	}
}
