@import '../../assets/scss/_shared.scss';

.profile-header {
	position: relative;
	max-width: 1440px;
	height: 263px;
	background: url('../../img/profile/profile-banner.jpg') no-repeat;
	background-size: contain;
	@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
		background-image: url('../../img/profile/profile-banner@2x.jpg');
	}
	@media #{$mq-tablet} {
		background-image: none;
		background: #004bbb; /* Old browsers */
		background: -webkit-linear-gradient(bottom left, #004bbb 0%, #006ad4 100%);
		background: -o-linear-gradient(bottom left, #004bbb 0%, #006ad4 100%);
		background: linear-gradient(
			to top right,
			#004bbb 0%,
			#006ad4 100%
		); /* FF3.6-15 */ /* Chrome10-25,Safari5.1-6 */ /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#004bbb', endColorstr='#006ad4',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
		height: 333px;
	}
	.profile-top-menu-wrapper {
		overflow: hidden;
		// height: 78px;
	}
	.profile-top-menu {
		@extend %h-align;
		bottom: 0;
		width: 100%;
		max-width: 1200px;
		background-color: #fff;
		padding: 0 50px;
		display: flex;
		justify-content: space-between;
		@media #{$mq-tablet} {
			overflow: auto;
			padding: 0;
			position: relative;
		}
		nav {
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 100%;
			padding-right: 70px;
			@media #{$mq-tablet} {
				padding-left: 21px;
				min-width: 1200px;
			}
		}
		a {
			color: rgba(0, 51, 153, 0.5);
			font-family: $EMSemi;
			font-size: 16px;
			display: inline-block;
			padding: 30px 0;
			&.active {
				color: #003399;
				border-bottom: solid 5px #003399;
				padding-bottom: 25px;
			}
		}
		button {
			border: none;
			cursor: pointer;
			align-self: center;
			height: 16px;
			width: 140px;
			background-color: #fff;
			&:hover .color-change {
				opacity: 0.75;
				transition: opacity 0.3s;
			}
			@media #{$mq-tablet} {
				margin-right: 21px;
				min-width: 140px;
			}
		}
	}
}

.app-download-icons {
	@media #{$mq-mobile} {
		text-align: center;
	}
	img {
		width: auto;
	}
}

.profile-header-info {
	@extend %container;
	color: #fff;
	display: flex;
	align-content: center;
	justify-content: space-between;
	padding: 70px 0;
	@media #{$mq-tablet} {
		padding: 28px 20px 30px;
		height: 255px;
		flex-direction: column;
	}
	.profile-info-text {
		font-family: $EMSemi;
		font-size: 13px;
		display: inline-block;
		padding-bottom: 7px;
		color: #fff;
	}
	.profile-user-info {
		margin-right: 150px;
	}
	.profile-points-info {
		margin-right: auto;
	}
	.profile-user-card {
		color: #fff;
		font-size: 13px;
		font-family: 'Ocra';
	}
	.profile-amount {
		color: #fff;
		font-size: 27px;
		font-family: $EMSemi;
		&.amount-big {
			font-size: 46px;
		}
	}
	button {
		border: 1px solid #ffffff;
		border-radius: 18px;
		height: 40px;
		padding: 0 20px;
		background-color: transparent;
		color: #fff;
		font-size: 13px;
		font-family: Arial, Helvetica, sans-serif;
		cursor: pointer;
		@media #{$mq-tablet} {
			max-width: 210px;
		}
	}
}

.profile-body {
	@extend %container;
	margin: 30px auto 50px;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	@media #{$mq-mobile} {
		padding: 0;
	}
}

.webview .profile-body {
	margin: 30px auto 90px;
}

.overview_section_row {
	display: flex;
	flex-basis: 735px;
	flex-direction: column;
	justify-content: flex-start;
	flex-wrap: wrap;
	.overview-section,
	.overview-section:nth-child(even) {
		max-width: 735px;
	}
	& + .overview-section:nth-child(even) {
		max-width: 405px;
		width: 100%;
	}
}

.overview-section {
	max-width: 735px;
	margin-bottom: 50px;
	&:nth-child(even) {
		max-width: 405px;
		width: 100%;
	}
	.overview-title-wrapper {
		display: flex;
		justify-content: space-between;
		align-items: center;
		a {
			color: rgba(0, 51, 153, 0.3);
			font-family: Arial, Helvetica, sans-serif;
			font-size: 12px;
			font-weight: bold;
			letter-spacing: 1.2px;
			text-transform: uppercase;
			display: inline-block;
			vertical-align: middle;
			span {
				height: 19px;
				display: inline-block;
				vertical-align: middle;
				padding-left: 10px;
			}
			&:hover {
				color: rgba(0, 51, 153, 0.9);
			}
			.menu-arrow {
				stroke: #0047ba;
			}
			@media #{$mq-mobile} {
				display: none;
			}
		}
	}
	h2 {
		color: #002164;
		font-family: $EMSemi;
		font-size: 18px;
		margin: 0 0 20px;
		font-weight: 400;
		@media #{$mq-mobile} {
			padding-left: 30px;
		}
	}
	.overview-section-content {
		position: relative;
		background-color: #fff;
		box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.05), 0 7px 13px 0 rgba(0, 0, 0, 0.08);
		padding: 10px 30px;
		li:not(:last-child) {
			border-bottom: 2px solid rgba(223, 234, 255, 0.4);
		}
	}
}

.esso-card {
	position: relative;
	width: 300px;
	border-radius: 9px;
	background-color: rgba(255, 255, 255, 0.97);
	box-shadow: 0 5px 13px 0 rgba(0, 0, 0, 0.1);
	padding: 25px;
	.generated-qr {
		max-width: 140px;
	}
	& .esso-logo-2d {
		position: absolute;
		top: 25px;
		right: 25px;
		height: 38px;
		width: 53px;
		background: url('../../img/esso-2d.svg') no-repeat;
	}
	& .detail-name {
		@extend %pale-bold;
		position: absolute;
		top: 3px;
		left: 0;
		max-width: 50px;
	}
	& .card-details {
		position: absolute;
		top: 130px;
		padding-left: 68px;
	}
	& .holder-name {
		@extend %pale-bold;
		color: #002164;
		font-size: 13px;
	}
	& .card-number {
		color: #002164;
		font-family: 'OcrA';
		font-size: 15px;
	}
	& .holder-details {
		position: relative;
		top: 0;
		margin-top: 30px;
		padding-left: 68px;
	}
	& .phone-number {
		@extend %pale-bold;
		color: #002164;
		font-size: 13px;
	}
	& .phone-description {
		@extend %pale-bold;
		font-size: 11px;
	}
}

.member-card-modal {
	@extend %full-align;
	max-width: 400px;
	outline: 0;
	z-index: 3;
	.show-member-card {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		outline: 0;
		box-shadow: 0;
	}
	p {
		color: #fff;
		font-family: $EMRegular;
		font-size: 18px;
		text-align: center;
	}
	.close-button-big {
		outline: 0;
		background: url('../../img/modals/close-button-big.svg') no-repeat;
		background-size: cover;
		border: none;
		padding: 0;
		margin-top: 30px;
		cursor: pointer;
		width: 55px;
		height: 55px;
	}
}

// .my-rewards-info {
//   display: flex;
//   padding: 20px 0;
//   align-items: center;
//   > span {
//     display: inline-block;
//     @media #{$mq-mobile} {
//       display: flex;
//       flex-direction: column-reverse;
//     }
//   }
//   .transaction-date {
//     display: inline-block;
//     margin-right: 80px;
//     @extend %pale-text;
//     color: rgba(0,51,153,0.3);
//     font-weight: 400;
//     font-size: 13px;
//     text-transform: capitalize;
//     min-width: 100px;
//     @media (max-width: 450px) {
//       margin-right: 20px;
//     }
//   }
//   .transaction-text {
//     display: inline-block;
//     vertical-align: top;
//     color: #002164;
//     font-family: Arial;
//     font-size: 13px;
//     font-weight: bold;
//     line-height: 1.6;
//   }
//   .transaction-amount {
//     color: rgba(0,51,153,0.5);
//     font-family: Arial;
//     font-size: 14px;
//     font-weight: bold;
//     margin-left: auto;
//   }
// }

%transaction-quantity {
	display: inline-block;
	vertical-align: top;
	margin-right: 8px;
	background-color: #c3cde6;
	color: #002164;
	font-family: Arial;
	font-size: 13px;
	font-weight: bold;
	line-height: 1.6;
	border-radius: 7px;
	padding: 0 6px;
	text-align: center;
	line-height: 1.4;
}

.transaction-quantity {
	@extend %transaction-quantity;
}

.my-card-info {
	display: flex;
	padding: 18px 0;
	align-items: center;
	justify-content: space-between;
	img {
		width: 33px;
		height: 21px;
		margin-right: 20px;
	}
	.my-card-inner {
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}
	.card-num {
		color: #002164;
		font-family: Arial;
		font-size: 13px;
		line-height: 1.6;
		font-weight: bold;
	}
	.card-name {
		color: rgba(0, 51, 153, 0.3);
		font-family: Arial;
		font-size: 13px;
		line-height: 15px;
		font-weight: 400;
		.master-card {
			font-weight: bold;
			color: inherit;
		}
	}
	.ui-dots {
		height: 35px;
		width: 35px;
		border-radius: 50%;
		display: inline-block;
		margin-left: auto;
		position: relative;
		cursor: pointer;
		&:hover {
			background-color: rgba(0, 51, 153, 0.05);
		}
		svg {
			@extend %full-align;
		}
	}
	&.card-unavailable {
		.card-num,
		.card-name {
			color: #e0e0e0;
		}
		img {
			opacity: 0.5;
		}
	}
}

.unlink-modal,
.block-modal,
.transfer-modal {
	outline: none;
	padding: 0;
	.unlink-modal-content,
	.block-modal-content,
	.transfer-modal-content {
		.close-modal-button {
			outline: none;
		}
		::placeholder,
		:-ms-input-placeholder,
		::-ms-input-placeholder {
			color: rgb(191, 199, 216);
		}
		.sub-text {
			color: rgb(123, 148, 201);
			line-height: 18px;
		}
		a {
			&:link,
			&:visited {
				color: #003399;
			}
			&:hover,
			&:active {
				text-decoration: underline;
			}
		}
	}
	.transfer-modal-content {
		h2 {
			margin-bottom: 31px;
		}
		.selector-dropdown {
			left: 50px;
			width: 120px;
		}

		.transfer-modal-attention {
			margin-top: 110px;
			border-radius: 8px;
			padding: 10px;
			background-color: rgba(0, 51, 153, 0.05);
		}
		.error {
			input.error {
				border-color: #f37427;
			}
		}
		.points-count {
			.invalid-message {
				font-size: 11px;
			}
		}

		.invalid-message {
			left: 0;
		}
		.transferee-info {
			& > .material-input {
				margin-top: 35px;
			}
		}
	}
	.unlink-modal-content,
	.block-modal-content {
		input {
			outline: none;
			box-sizing: border-box;
			padding: 15px;
			text-align: center;
			border: 1px solid #dfeaff;
			border-radius: 3px;
			font-size: 13px;
			margin: 7px 0 30px;
		}
	}
	button.proceed {
		@extend %proceed-button;
	}
	.transferer-info {
		border-bottom: 1px solid #dfeaff;
		> div {
			display: flex;
			justify-content: space-between;
			padding-bottom: 6px;
		}
		strong {
			color: rgba(0, 51, 153, 0.4);
			font-size: 12px;
			font-weight: bold;
			line-height: 14px;
		}
		.transferer_number {
			color: rgba(0, 33, 100, 0.5);
			font-family: Ocra;
			font-size: 13px;
			letter-spacing: 0.76px;
			line-height: 13px;
		}
		.points_total {
			color: rgba(0, 33, 100, 0.5);
			font-family: #{$EMRegular};
			font-size: 13px;
			line-height: 15px;
			margin-bottom: 5px;
		}
	}
}

.selector-dropdown {
	cursor: pointer;
	display: inline-block;
	border-radius: 18px;
	background-color: #003399;
	font-size: 13px;
	color: #ffffff;
	font-family: Arial;
	font-size: 13px;
	width: 175px;
	position: absolute;
	li span::after {
		content: '\0203A';
		position: absolute;
		top: 4px;
		left: 25px;
		transform: rotate(90deg);
		font-size: 20px;
		display: inline-block;
	}
	&.open li span::after {
		transform: rotate(-90deg);
		top: 10px;
		left: 23px;
	}
	&.white {
		background-color: #fff;
		border: 1px solid #003399;
		color: #003399;

		span {
			color: #003399;
		}

		li {
			padding: 9px 0;
		}
	}
	span {
		display: none;
		margin-left: -3px;
		margin: 0 20px;
		margin-right: 25px;
		padding: 12px 0;
		width: calc(100% - 40px);
		line-height: 1.5;
		color: #fff;
		&:first-child {
			display: inline-block;
			position: relative;
			&:not(.pagination-dropdown):after {
				position: absolute;
				content: '';
				right: -10px;
				top: 53%;
				transform: translateY(-50%);
				width: 10px;
				height: 7px;
				background: url('../../img/profile/ui-chevrone-blue.svg') no-repeat;
				background-size: contain;
			}
		}
	}
	&.expanded {
		span {
			display: inline-block;
			border-bottom: 1px solid rgba(0, 51, 153, 0.1);
		}
	}
	li {
		svg {
			display: none;
			margin-left: 5px;
		}
	}
	li:first-child {
		svg {
			display: inline-block;
		}
	}
	li:not(:first-child) {
		border-top: 2px solid rgba(249, 249, 249, 0.1);
	}
	&:not(.open) {
		li {
			display: none;
			border: none;
		}
	}
	li.active {
		display: block;
	}
	&.open {
		li span::after {
			display: none;
		}
		li.active:not(:first-child) {
			border-top: 2px solid rgba(249, 249, 249, 0.1);
		}
		li:first-child span::after {
			display: inline-block;
		}
	}
	&:not(.open) {
		li.active {
			span::after {
				display: inline-block;
			}
		}
	}
}

.page-controls-wrapper {
	display: flex;
	position: relative;
	align-items: center;
	width: 100%;
	margin: 10px 0;
	@media (max-width: 425px) {
		padding: 0 10px;
	}
	&.hidden {
		display: none;
	}
	.controls-desc {
		color: #454545;
		font-family: Arial;
		font-size: 13px;
		margin-right: 15px;
		@media (max-width: 767px) {
			display: none;
		}
	}
	.selector-dropdown {
		top: -10px;
		left: 50px;

		span:first-child::after {
			right: 0;
			left: auto;
			top: 7px;
		}

		@media (max-width: 767px) {
			left: 10px;
		}
	}
}

.transaction-table {
	margin: 30px 0;
	width: 100%;
	box-shadow: 0 2px 13px 0 rgba(0, 0, 0, 0.08);
	table {
		width: 100%;
		text-align: left;
		border-spacing: 0;
		background-color: #fff;
	}
	th {
		color: rgba(0, 51, 153, 0.3);
		font-family: Arial;
		font-size: 12px;
		font-weight: bold;
		letter-spacing: 1.2px;
		text-transform: uppercase;
		padding: 25px 0;
		cursor: pointer;
		text-align: left;
		&:hover {
			color: rgba(0, 51, 153, 0.4);
		}
		@media #{$mq-mobile} {
			&:nth-child(3),
			&:nth-child(4) {
				display: none;
			}
		}
		svg {
			position: relative;
			bottom: 1px;
			vertical-align: inherit;
		}
		svg path {
			stroke: #003399;
		}
	}
	th.not-sortable {
		cursor: auto;
		pointer-events: none;
	}
	th:first-child,
	td:first-child {
		padding-left: 30px;
	}
	tr {
		background-color: #fff;
	}
	tr:nth-child(even) {
		background-color: #fcfdfe;
	}
	tr:last-child td {
		border: none;
	}
}
.transfer-table-row {
	.table-frequency {
		color: rgba(0, 51, 153, 0.4);
		font-family: Arial;
		font-size: 13px;
	}
	.table-amount {
		color: #002164;
		font-family: Arial;
		font-size: 14px;
		font-weight: bold;
		line-height: 20px;
	}
}
.transaction-table-row {
	color: #002164;
	font-family: Arial;
	font-size: 13px;
	td {
		padding: 25px 0;
		border-bottom: 1px solid rgba(223, 234, 255, 0.4);
		@media #{$mq-mobile} {
			&:nth-child(3),
			&:nth-child(4) {
				display: none;
			}
		}
	}

	.table-name {
		font-weight: bold;
	}
	.table-cat,
	.table-type {
		color: rgba(0, 51, 153, 0.4);
	}
	&.positive-amount .table-type {
		color: #27d9a1;
	}

	.table-punten {
		font-weight: bold;
	}
}
.count-dropdown-wrapper {
	display: inline-block;
	&.hidden {
		display: none;
	}
	.selector-dropdown {
		width: 75px;
		.active isvg {
			display: inline;
		}
	}
	.results-count-dropdown {
		display: inline-block;
		padding: 12px 20px;
		border: 1px solid #003399;
		border-radius: 18px;
		color: #003399;
		font-family: Arial;
		font-size: 13px;
		svg {
			position: relative;
			bottom: 1px;
		}
		svg path {
			stroke: #003399;
		}
		span:first-child {
			display: inline-block;
			margin-right: 8px;
		}
	}
	.pager-info {
		position: relative;
		left: 90px;
		color: #454545;
		font-family: Arial;
		font-size: 13px;
		margin-right: 15px;
		margin-left: 15px;
		@media (max-width: 590px) {
			display: none;
		}
	}
}

.transactions-banner {
	height: 340px;
	width: 100%;
	background: url('../../img/profile/transactions-banner@2x.jpg') no-repeat;
	background-size: contain;
	margin-top: 50px;
	@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
		background-image: url('../../img/profile/transactions-banner@2x.jpg');
	}
	@media #{$mq-tablet} {
		background-size: cover;
	}
	.banner-text-wrapper {
		max-width: 450px;
		margin: 50px 0 0 50px;
		@media #{$mq-tablet} {
			margin: 35px;
		}
	}
	h2 {
		@extend %esso-title;
		color: #fff;
		font-family: $EMSemi;
	}
	p {
		@extend %esso-text;
	}
	a {
		@extend %blue-btn;
		margin-top: 25px;
	}
}

.pagination-links {
	margin-left: auto;
	display: flex;
	align-content: center;
	justify-content: space-between;
	a {
		color: rgba(0, 51, 153, 0.3);
		font-family: Arial, Helvetica, sans-serif;
		font-size: 12px;
		font-weight: bold;
		letter-spacing: 1.2px;
		text-transform: uppercase;
		display: inline-block;
		vertical-align: middle;
		display: inline-block;
		span {
			height: 19px;
			display: inline-block;
			vertical-align: middle;
			padding-left: 10px;
		}
		&:not(:last-child) {
			margin-right: 20px;
		}
		&:first-child span {
			transform: rotateY(180deg);
		}
		&:last-child span {
			padding-left: 5px;
		}
		&:hover {
			color: rgba(0, 51, 153, 0.9);
		}
		.menu-arrow {
			stroke: #0047ba;
		}
		&.disabled {
			opacity: 0.3;
			pointer-events: none;
		}
		&.prev-page-link {
			svg {
				transform: rotate(180deg);
				margin-right: 9px;
			}
		}
	}
}

h2.rewards-filter-title {
	color: #003399;
	font-family: $EMSemi;
	font-size: 24px;
	margin-bottom: 30px;
	line-height: 1.4;
	margin-top: 0;
	max-width: 300px;
}

.rewards-filter {
	li {
		display: flex;
		padding: 20px 0;
		align-items: center;
		font-family: $EMSemi;
		font-size: 13px;
		cursor: pointer;
		color: #002164;
		a {
			color: #002164;
		}
		button {
			border: none;
			cursor: pointer;
			color: #002164;
			padding: 0;
			background: transparent;
		}
		svg {
			margin-right: 20px;
		}
	}
}

.user-cards-wrapper,
.user-data-wrapper {
	.overview-section {
		flex-basis: 50%;
		max-width: calc(50% - 10px);
		@media #{$mq-tablet} {
			flex-basis: 100%;
			max-width: 100%;
			margin: 0;
		}
		button.proceed {
			@extend %proceed-button;
		}
		.static-list {
			opacity: 0.5;
			color: #002164;
			font-family: Arial;
			font-size: 13px;
			line-height: 1.4;
			padding-left: 15px;
			li {
				list-style-type: disc;
				margin: 0;
				padding: 4px 0;
				border-bottom: none;
			}
		}
	}
}

.user-cards-wrapper {
	.overview-section:last-of-type {
		.overview-section-content {
			padding: 0;
			.link-card-wrapper {
				padding: 10px 30px;
			}
		}
	}
}

.link-card-wrapper {
	h2 {
		color: #003399;
		font-family: Arial;
		font-size: 13px;
		font-weight: bold;
		display: flex;
		align-items: center;
		border-bottom: 2px solid rgba(223, 234, 255, 0.4);
		padding: 20px 0;
		margin: 0 0 20px;
		line-height: 1.25;
		span{
			line-height: inherit;
			font-weight: inherit;
			font-family: inherit;
			color: inherit;
		}
	}
	.card-add-button {
		height: 30px;
		width: 30px;
		border-radius: 5px;
		background-color: #003399;
		display: inline-block;
		position: relative;
		margin-right: 20px;
		cursor: pointer;
		display: flex;
		justify-content: center;
		span {
			color: #fff;
			font-size: 24px;
			font-weight: normal;
			transition: transform ease 0.2s;
		}
		&.extended {
			span {
				transform: rotate(45deg);
			}
		}
	}
	p {
		opacity: 0.5;
		color: #002164;
		font-family: Arial;
		font-size: 13px;
		margin-top: 20px;
		line-height: 1.4;
	}

	.extended {
		p {
			opacity: 1;
			margin: 25px 0 40px;
		}
	}
	.card-options {
		margin-bottom: 10px;
		.reg-option {
			height: 80px;
			width: 85px;
			margin-right: 15px;
			&.selected {
				&::after {
					content: '\2714';
					color: #9bd836;
					position: absolute;
					margin-left: 68px;
				}
			}
			img {
				max-width: 33px;
			}
		}
	}
	.material-input {
		.material-input {
			flex: 4;
			margin: 0;
		}
		.input-link {
			max-width: 85px;
		}
	}
	&.disabled {
		h2 {
			opacity: 0.4;
		}
		.card-add-button {
			cursor: default;
		}
	}
}

.link-card-button.proceed {
	position: relative !important;
}

.user-data-wrapper {
	.switch-label {
		margin: 35px 0;
	}
	p {
		color: rgba(0, 33, 100, 0.5);
		font-family: Arial;
		font-size: 13px;
		line-height: 1.6;
	}
	.button-holder {
		width: 100%;
		text-align: center;
	}
	.big-btn {
		@extend %blue-btn;
		padding: 16px 120px;
		@media #{$mq-mobile} {
			width: 100%;
			padding: 16px 20px;
		}
	}
	.overview-section {
		margin-bottom: 30px;
		width: 570px;
		p a {
			color: #003399;
			text-decoration: underline;
		}
	}
}

.overview-page-note {
	text-align: right;
	margin-top: 25px;
}

.flex-col {
	flex-basis: 50%;
	max-width: calc(50% - 10px);
	@media #{$mq-tablet} {
		flex-basis: 100%;
		max-width: 100%;
	}
	.overview-section {
		max-width: 100%;
	}
}

.orders-table {
	position: relative;
	width: 100%;
	text-align: left;
	border-collapse: collapse;

	// tr:nth-child(2n + 1) {
	//   background-color: rgba(0,51,153,0.01);
	// }

	tr:hover {
		td:after {
			opacity: 1;
		}
	}

	tr:last-child {
		td {
			border-bottom: none;
		}
	}

	tr {
		@media (max-width: 600px) {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			width: 100%;
			border-bottom: 1px solid #dfeaff;
			padding: 10px 0;

			&:last-child {
				border-bottom: none;
			}
		}
	}

	thead tr:last-child {
		@media (max-width: 600px) {
			border-bottom: 1px solid #dfeaff;
		}
	}

	th {
		padding: 25px 10px;
		text-transform: uppercase;
		font: bold 12px/1.2 Arial, sans-serif;
		color: rgba(0, 51, 153, 0.3);
		letter-spacing: 0.1em;
		border-bottom: 1px solid #dfeaff;

		@media (max-width: 600px) {
			border-bottom: none;
			&.orders-type,
			&.orders-action {
				display: none;
			}
		}
	}

	td {
		padding: 0;
		border-bottom: 1px solid #dfeaff;
		position: relative;
		z-index: 0;

		&:after {
			content: '';
			position: absolute;
			z-index: -1;
			left: 0;
			right: 0;
			top: 0;
			height: 100%;
			background-color: rgba(0, 51, 153, 0.06);
			opacity: 0;
			transition: opacity 0.1s;
		}

		> .cell-wrapper {
			display: table-cell;
			vertical-align: middle;
			padding: 25px 10px;
			min-height: 88px;
		}

		@media (max-width: 600px) {
			&:after {
				display: none;
			}
		}

		&:first-child {
			&:after {
				left: -30px;
			}
		}
		&:last-child {
			&:after {
				right: -30px;
			}
		}

		&.orders-type {
			font: 400 13px/1.2 Arial, sans-serif;
			color: rgba(0, 51, 153, 0.3);

			@media (max-width: 600px) {
				display: none;
			}
		}

		@media (max-width: 600px) {
			display: inline-block;
			vertical-align: top;
			border: none;
			padding: 10px;

			&.orders-action {
				display: block;
				width: 100%;
			}
		}
	}
	.transaction-date {
		color: #002164;
		font-weight: 400;
		font-size: 11px;
		line-height: 1.5;
	}
	.transaction-text {
		display: inline-block;
		vertical-align: top;
		color: #002164;
		font-family: Arial;
		font-size: 13px;
		font-weight: bold;
		line-height: 1.6;
	}
	.transaction-amount {
		color: #002164;
		font-family: Arial;
		font-size: 13px;
		margin-left: auto;
	}
	.transaction-details {
		font: 600 13px $EMSemi;
		color: #002164;
		cursor: pointer;
		transition: opacity 0.1s;
		.details-text {
			display: inline-block;
			vertical-align: middle;
			color: inherit;
		}
		img {
			display: inline-block;
			vertical-align: middle;
			margin-left: 15px;
		}

		&:hover {
			opacity: 0.3;
		}
	}
}

@keyframes slideLeft {
	from {
		transform: translateX(100%);
	}
	to {
		transform: translateX(0);
	}
}

.order-modal-wrapper {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 3;
}

.order-modal {
	position: fixed;
	z-index: 4;
	right: 0;
	top: 0;
	bottom: 0;
	width: 500px;
	max-width: 100%;
	background-color: #ffffff;
	box-shadow: 0 7px 13px 0 rgba(0, 0, 0, 0.08);
	overflow-y: auto;
	overflow-x: hidden;
	transform: translateX(0);
	animation: slideLeft 0.3s;

	&.hidden {
		transform: translateX(100%);
		transition: transform 0.3s;
	}

	&-close {
		position: absolute;
		top: 49px;
		right: 30px;
		width: 12px;
		height: 12px;
		background-image: url('../../img/cross.svg');
		background-size: contain;
		cursor: pointer;

		@media #{$mq-mobile} {
			top: 30px;
		}
	}

	&-head {
		padding: 49px 30px 30px;
		border-radius: 2px 2px 0 0;
		background-color: #ffffff;
		box-shadow: 0 7px 13px 0 rgba(0, 0, 0, 0.03);

		.top-content {
			padding-bottom: 30px;
			display: flex;
			align-items: center;
		}
	}

	&-content {
		padding: 40px 30px;
	}

	.order-image {
		position: relative;
		height: 0;
		padding-bottom: 22%;
		width: 125px;
		margin-right: 25px;

		img {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			width: 100%;
		}
	}

	.order-title {
		font: 600 16px $EMSemi;
		color: rgba(0, 51, 153, 0.3);
	}

	.order-id {
		color: #003399;
		font: 600 22px $EMSemi;
	}

	.order-type {
		margin-bottom: 7px;
		text-transform: uppercase;
		font: 700 10px Arial, sans-serif;
		color: rgba(0, 51, 153, 0.3);
		letter-spacing: 1px;
	}

	.order-name {
		font: 700 13px Arial, sans-serif;
		color: #002164;
	}

	.order-data {
		font: 400 13px Arial, sans-serif;
		color: #002164;
	}

	.order-info {
		padding-bottom: 40px;

		&-flex {
			display: flex;
			justify-content: space-between;
		}
		&-item {
			margin-right: 80px;
		}
	}

	.order-barcode {
		margin-top: 15px;
		text-align: center;
		.barcode-num {
			color: #002164;
			font: 700 10px Arial, sans-serif;
		}
	}

	.order-text {
		margin-top: 20px;
		text-align: center;
		color: rgba(0, 51, 153, 0.3);
		font: 400 13px Arial;
	}

	.order-download-link {
		padding: 30px 30px 0;
		border-top: 1px solid #f1f6ff;
		text-align: center;

		span {
			font: 600 13px $EMSemi;
			color: #002061;
			cursor: pointer;
		}
		.link-text,
		.isvg {
			display: inline-block;
			vertical-align: middle;
		}
		.link-text {
			margin-left: 10px;
		}
	}

	.order-status {
		position: relative;
		padding-left: 16px;
		&:before {
			content: '';
			position: absolute;
			left: 0;
			top: 4px;
			width: 8px;
			height: 8px;
			background-color: #27d9a1;
			border-radius: 50%;
		}
	}

	.order-action {
		margin-top: 20px;
		transition: color 0.3s;

		> span {
			display: inline-block;
			padding-right: 30px;
			cursor: pointer;
		}
		&.hidden {
			visibility: hidden;
			opacity: 0;
		}
		&.disabled {
			> span {
				position: relative;
				pointer-events: none;
				cursor: default;
				opacity: 0.7;

				&:after {
					content: '';
					position: absolute;
					top: calc(50% - 10px);
					right: 0;
					width: 20px;
					height: 20px;
					background: url('../../img/loader.svg');
					background-size: contain;
				}
			}
		}
	}
}

.orders-placeholder {
	display: flex;
	align-items: center;
	padding: 40px 20px;
	color: rgba(0, 33, 100, 0.5);
	font: 400 13px Arial, sans-serif;
	
	p, span{
		color: inherit;
	}

	.placeholder-link {
		float: right;
		color: #002061;
		text-decoration: underline;
	}
	.placeholder-icon {
		margin-right: 58px;

		@media (max-width: 425px) {
			display: none;
		}
	}

	@media (max-width: 425px) {
		h2 {
			padding-left: 0;
		}
	}
}

.orders-wrapper {
	position: relative;
	.reward-list-controls {
		position: absolute;
		bottom: -50px;
		right: -30px;
		span {
			cursor: pointer;
			color: rgba(0, 51, 153, 0.3);
			font-family: Arial, Helvetica, sans-serif;
			font-size: 12px;
			font-weight: bold;
			letter-spacing: 1.2px;
			text-transform: uppercase;
			vertical-align: middle;
			display: inline-flex;
			@extend %flex-align;
			&:hover {
				color: rgba(0, 51, 153, 0.9);
			}
			&.disabled {
				opacity: 0.3;
				pointer-events: none;
			}
			img {
				height: 13px;
				width: 13px;
			}
			&:nth-child(2) {
				padding-left: 10px;
				img {
					margin-left: 10px;
					transform: rotate(180deg);
				}
			}
		}
	}
}

.order-details-modal {
	position: fixed;
	top: 0;
	right: 0;
	width: 500px;
	max-width: 100%;
	height: 100%;
	z-index: 4;
	background: #fff;
	padding-top: 40px;
	padding-bottom: 40px;
	transform: translateX(100%);
	transition: transform 0.3s;
	overflow-y: auto;

	&.active {
		transform: translateX(0);
	}

	.back-btn {
		position: absolute;
		top: 20px;
		left: 20px;
		width: 30px;
		height: 20px;
		background: url('../../img/prev-arr.svg') no-repeat;
		background-size: contain;
		background-position: center;
		cursor: pointer;
	}

	iframe {
		width: 100%;
	}
}

ul.default-data {
	color: rgba(0, 33, 100, 0.5);
	li span {
		color: rgba(0, 33, 100, 0.5) !important;
	}
	li.transaction-info {
		& span {
			color: rgba(0, 33, 100, 0.5);
		}
	}
	p {
		color: rgba(0, 33, 100, 0.5) !important;
		font-family: Arial;
		font-size: 13px;
		line-height: 1.6;
	}
	a {
		color: #002164;
		&:hover {
			text-decoration: underline;
		}
	}
}

.user-rewards-wrapper {
	.overview-section {
		width: 100%;
	}

	@media (max-width: 1200px) {
		.overview-section {
			flex-basis: 100%;
			max-width: 100%;
		}
	}

	@media (max-width: 600px) {
		.overview-section:nth-child(2) {
			display: none;
		}
	}
}

.box-input {
	height: 45px;
	width: 286px;
	border: 1px solid #dfeaff;
	border-radius: 3px;
	font-size: 13px;
	padding: 0 13px;
	color: #002164;
}

.full-button {
	@extend %blue-btn;
	width: 100%;
	margin-top: 20px;
	padding: 10px 20px;
}

.main-nav.active-profile .profile-page-navlink {
	color: #003399;
}

.internal_profile-wrapper {
	overflow: hidden;
}

.popup-menu-items {
	max-width: 13px !important;
	width: 100% !important;
}

.transaction-loading-indicator {
	width: 100%;
	height: 50px;
	position: absolute;
	background: #fff;
	div {
		@extend %full-align;
	}
}

.overview-section .transaction-loading-indicator {
	left: 0;
}

.loader-holder {
	min-height: 50px;
}
.transaction-header-wrapper {
	display: flex;
	flex-wrap: wrap;
	align-items: center;

	.page-controls-wrapper {
		width: auto;
		padding-right: 200px;

		@media (max-width: 767px) {
			margin-top: 30px;
			margin-bottom: 0;
			width: 100%;
			height: 38px;
		}
	}
}

.section-selector-wrapper {
	display: flex;
	background: #fff;
	border: 1px solid #003399;
	border-radius: 20px;
	padding: 5px;
	min-width: 290px;
	min-height: 45px;
	margin-right: 30px;

	@media (max-width: 767px) {
		margin: 0 10px;
	}
	.section-selector-item {
		@extend %flex-align;
		z-index: 2;
		padding: 8px 12px;
		border-radius: 20px;
		color: #000;
		background-color: rgba(0, 51, 153, 0);
		transition: 0.1s;
		&.active {
			color: #fff;
			background-color: rgba(0, 51, 153, 1);
			transition: 0.2s;
			span{
				color: #fff;
			}
		}
		span {
			margin-left: 10px;
			font-size: 12px;
			line-height: 1.5;
		}
	}
	.background-fluid-block {
		position: absolute;
		left: 10px;
		width: 143px;
		height: 33px;
		border-radius: 20px;
		background-color: #003399;
		z-index: 1;
	}
}
.transactions-ui-dots {
	height: 35px;
	width: 35px;
	border-radius: 50%;
	display: inline-block;
	margin-left: auto;
	position: relative;
	cursor: pointer;

	.transfers-submenu {
		bottom: 100%;
		right: 100%;
		transform: translate(4%, 12%);
		display: block;
		position: absolute;
		border-radius: 2px;
		background-color: #ffffff;
		box-shadow: 0 7px 13px 0 rgba(0, 0, 0, 0.08);
		&-item {
			@extend %flex-align;
			position: relative;
			padding: 20px 60px 20px 20px;
			margin: 10px;
			&:hover {
				transition: 0.2s;
				background-color: rgba(0, 75, 153, 0.05);
				border-radius: 5px;
			}
			&:nth-child(odd) {
				&:after {
					position: absolute;
					content: '';
					bottom: -5px;
					left: 50%;
					transform: translateX(-50%);
					width: 160px;
					height: 1px;
					background: #dfeaff;
				}
			}
			img {
				width: 20px;
				height: 20px;
				margin-right: 10px;
			}

			span {
				@extend %white-blue-text;
				white-space: nowrap;
			}
		}
	}

	&:hover {
		background-color: rgba(0, 51, 153, 0.05);
	}
	svg {
		@extend %full-align;
	}
}

.empty-points-history {
	background-color: #fff;
	padding: 30px 70px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	.description-block {
		.descr-title {
			@extend %esso-title;
			font-family: $EMSemi;
			font-size: 22px;
			line-height: 27px;
		}
		.description {
			@extend %esso-text;
			color: rgba(0, 33, 100, 0.5);
			font-size: 13px;
			a {
				@extend %info-link;
				color: #002164 !important;
			}
		}
	}
}

.frequency-section {
	position: relative;
	z-index: 5;
	@extend %container;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	padding: 25px 30px 25px 58px;
	margin: 30px auto 0;
	box-shadow: 0 7px 13px 0 rgba(0, 0, 0, 0.08);
	background-color: #fff;
	border-radius: 8px;
	color: $textColor;

	.frequency-info {
		position: relative;
		&:before {
			content: '';
			position: absolute;
			top: calc(50% - 4px);
			left: -28px;
			height: 8px;
			width: 8px;
			background-color: #27d9a1;
			border-radius: 50%;
		}

		@media #{$mq-tablet} {
			margin-bottom: 20px;

			&:before {
				top: 14px;
			}
		}
	}

	h3 {
		margin: 0 0 10px;
		font-size: 16px;
		font-family: $EMSemi;
	}

	p {
		font-size: 13px;
		font-family: Arial;
		margin: 0;
	}

	b {
		font-family: $EMSemi;
	}

	.btn {
		@extend %orange-btn;
	}

	@media (max-width: 1220px) {
		margin: 30px 0;
	}

	@media #{$mq-tablet} {
		display: block;
	}
}

@keyframes fadingModal {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

.frequency-modal {
	position: absolute;
	top: 25px;
	right: -60px;
	width: 405px;
	max-width: 100%;
	border-radius: 2px;
	background-color: #ffffff;
	box-shadow: 0 7px 13px 0 rgba(0, 0, 0, 0.08);
	padding: 65px 30px 30px;
	overflow: hidden;

	animation: fadingModal 0.1s ease-in-out;

	@media (max-width: 1330px) {
		right: -20px;
	}

	@media #{$mq-tablet} {
		right: 0;
		left: 0;
		width: 100%;
		max-width: none;
		top: calc(100% - 74px);
	}

	.close-btn {
		position: absolute;
		top: -51px;
		left: calc(50% - 51px);
		width: 102px;
		height: 102px;
		border-radius: 50%;
		background-color: rgba(178, 193, 224, 0.1);
		background-image: url('../../img/arrow-bottom.svg');
		background-repeat: no-repeat;
		background-position: center 75%;
		cursor: pointer;
	}

	h4 {
		position: relative;
		padding-bottom: 30px;
		padding-left: 18px;
		margin: 0;
		font: 700 13px Arial;

		&:before {
			content: '';
			position: absolute;
			top: 5px;
			left: 0;
			height: 8px;
			width: 8px;
			background-color: #27d9a1;
			border-radius: 50%;
		}
	}

	hr {
		margin: 23px 0 16px;
		border-color: #dfeaff;
		box-shadow: none;
		background-color: transparent;
		border-style: solid;
	}

	.progress-label {
		display: inline-block;
		padding: 5px 8px;
		margin-bottom: 28px;
		border-radius: 6px;
		background-color: rgba(243, 116, 39, 0.2);
		font-size: 11px;
		font-weight: 700;
		font-family: Arial;
		color: #f37427;
	}

	.time-info {
		font-family: Arial;
		font-size: 13px;
		line-height: 18px;
		color: rgba(0, 51, 153, 0.4);
		.bullet {
			position: relative;
			padding-left: 15px;
			padding-top: 10px;

			&:before {
				content: '';
				position: absolute;
				top: 16px;
				left: 5px;
				width: 5px;
				height: 5px;
				border-radius: 50%;
				background: rgba(0, 51, 153, 0.6);
			}
		}
	}

	.frequency-time {
		padding-bottom: 10px;
		font: 400 24px $EMSemi;

		.time-seconds {
			padding-right: 4px;
			font-size: 13px;
			font-family: $EMRegular;
		}
		.time-text {
			padding-left: 4px;
			font: 400 13px $EMRegular;
			opacity: 0.4;
		}
	}
}

.frequency-progress-bar {
	position: relative;
	height: 12px;
	margin-bottom: 43px;
	background-image: url('../../img/profile/progress-bar-empty.png');
	background-position: left top;
	background-size: 100%;
	background-repeat: no-repeat;

	@media #{$mq-tablet} {
		width: 260px;
		height: 9px;
	}

	.inner-bar {
		position: absolute;
		left: 0;
		top: 0;
		width: 50%;
		height: 100%;
		background-image: url('../../img/profile/progress-bar-full.png');
		background-position: left top;
		background-size: 345px 100%;
		background-repeat: no-repeat;

		@media #{$mq-tablet} {
			background-size: 260px 100%;
		}
	}

	.bar-note {
		position: absolute;
		bottom: calc(-100% - 10px);
		font: 400 11px Arial;
		color: #e0e0e0;

		&.left {
			left: 22%;
		}

		&.right {
			right: 20%;
		}
	}
}

.order-actions-list {
	display: flex;
	justify-content: flex-start;
	flex-wrap: wrap;
	counter-reset: item;
	padding: 0;
	margin: 20px 0 0;
	li.action-item {
		position: relative;
		border: none;
		margin: 0 50px 20px 0;
		padding-left: 40px;

		&:not(:last-child) {
			border: none;
		}
		.order-action {
			margin: 0 0 20px;
		}
		&:before {
			content: counter(item) '.';
			counter-increment: item;
			position: absolute;
			left: 0;
			top: 0;
			opacity: 0.6;
			@extend %transaction-quantity;
		}
	}
}

.wrapper-log-out-button{
	display: none;
	
	@media #{$mq-tablet} {
		display: block;
		text-align: center;
		width: 100%;
		margin: 0 1rem;
		
		.log-out-button{
			display: inline-flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			max-width: 400px;
			background-color: #F2F5FA;
			height: 48px;
			border-radius: 4px;
			
			span{
				font-family: 'EMprint W04 Regular', sans-serif;
				font-weight: 600;
				font-size: 14px;
				color: #003399;
				margin-left: 5px;
			}
		}
	}
}
