// Sizes
$mainWidth: 1200px;

// Colors
$white: #fff;
$black: #000;
$snow: #f9f9f9;
$solitude: #e9f0f8;
$smalt: #039;
$sapphire: #002164;

$mainBgColor: $snow;

:export {
  white: $white;
  solitude: $solitude;
}

//Fonts
$EMSemi: "EMprint W04 Semibold";
$EMRegular: "EMprint W04 Regular";

//== Typography
$fontFamilyBase: Arial, Helvetica, sans-serif;
$fontSizeBase: 14px;
$lineHeightBase: 1.5;
$textColor: $sapphire;

//Breakpoints
$desktop-l: 1440px;
$desktop: 1200px;
$desktop-m: 1024px;
$tablet: 768px;
$mobile: 425px;
$mobile-s: 320px;

// Media Queries
$mq-desktop-l: "(max-width: #{$desktop-l})";
$mq-desktop: "(max-width: #{$desktop})";
$mq-desktop-m: "(max-width: #{$desktop-m})";
$mq-tablet: "(max-width: #{$tablet})";
$mq-mobile: "(max-width: #{$mobile})";
$mq-mobile-s: "(max-width: #{$mobile-s})";
$mq-retina: "(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)";

// Animations
$transitionBase: all 0.3s ease;
$transitionColor: color 0.3s ease;
$transitionOpacity: opacity 0.3s ease;

// Images path
$imagesPath: "../../assets/images";

// Extends
%container {
  max-width: $mainWidth;
  margin: 0 auto;
  position: relative;

  @media (max-width: $mainWidth + 20px) {
    padding-left: 20px;
    padding-right: 20px;
  }
}

// Reset for button
%btn-reset {
  background: none;
  outline: none;
  border: none;
  cursor: pointer;
  padding: 0;
  transition: $transitionBase;

  &:focus {
    outline: none;
  }
}

%section-mb {
  margin-bottom: 90px;

  @media #{$mq-tablet} {
    margin-bottom: 60px;
  }
}

%flex-align {
  display: flex;
  justify-content: center;
  align-items: center;
}

%full-align {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
%v-align {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

%h-align {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

%esso-title {
  color: $textColor;
  font-family: $EMRegular;
  font-size: 34px;
  font-weight: 300;
  margin: 0.5em 0;
  @media (max-width: 875px) {
    font-size: 28px;
  }
}

%esso-text {
  color: $white;
  font-family: $fontFamilyBase;
  font-size: $fontSizeBase;
  line-height: $lineHeightBase;
}

%section {
  max-width: $mainWidth;
  margin-top: 65px;
}

%bold-title {
  color: $textColor;
  font-family: $EMSemi;
  font-size: 18px;
  font-weight: 500;
}

%pale-text {
  color: $sapphire;
  font-family: $fontFamilyBase;
  font-size: $fontSizeBase;
  line-height: $lineHeightBase;
}

%pale-bold {
  color: rgba(0, 51, 153, 0.4);
  font-family: $fontFamilyBase;
  font-size: 12px;
  font-weight: bold;
}

%blue-btn {
  padding: 16px 27px;
  display: inline-block;
  border: 1px solid $smalt;
  border-radius: 2px;
  background-color: $smalt;
  box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.05), 0 7px 13px 0 rgba(0, 0, 0, 0.08);
  color: $white;
  font-family: $EMSemi;
  font-size: $fontSizeBase;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  transition: $transitionBase;

  &:hover {
    background-color: darken($color: $smalt, $amount: 2);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
  }
}

%orange-btn {
  padding: 16px 31px;
  display: inline-block;
  background-color: #F37427;
  border:none;
  border-radius: 40px;
  box-shadow: 0 7px 13px 0 rgba(0,0,0,0.08);
  color: $white;
  font-family: $EMSemi;
  font-size: $fontSizeBase;
  text-align: center;
  cursor: pointer;
  &:focus,
  &:active {
    outline: none;
  }
}

%white-btn {
  display: inline-block;
  padding: 16px 25px 16px 45px;
  border: 1px solid $white;
  border-radius: 2px;
  box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.05), 0 7px 13px 0 rgba(0, 0, 0, 0.08);
  font-size: $fontSizeBase;
  font-family: $EMSemi;
}

%link-arrow {
  color: rgba(0, 33, 100, 0.7);
  font-family: $fontFamilyBase;
  font-size: $fontSizeBase;
  line-height: 16px;
  text-align: right;
  &:hover {
    color: rgba(0, 33, 100, 0.9);
  }
  &::after {
    content: "";
    background: url("#{$imagesPath}/icons/chevron-right-o.svg") no-repeat 0 50%;
    display: inline-block;
    vertical-align: sub;
    width: 12px;
    height: 16px;
    margin-left: 8px;
  }
}

%highlight {
  position: relative;
  &:after {
    content: "";
    position: absolute;
    bottom: -8px;
    left: 0;
    width: 26px;
    height: 2px;
    background: $smalt;
  }
}

%small-text {
  color: $textColor;
  font-family: $EMRegular;
  font-size: 12px;
}

%material-input {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 30px 0 15px 0;
  .group {
    position: relative;
    width: 100%;
  }
  input {
    font-size: $fontSizeBase;
    font-family: $fontFamilyBase;
    color: $textColor;
    padding: 7px 10px 7px 0;
    display: block;
    width: 100%;
    border: none;
    border-bottom: 1px solid #dfeaff;
    background: transparent;
    box-shadow: none;
    &::placeholder {
      color: rgba(0, 33, 100, 0.3);
    }
    &:-webkit-autofill:first-line {
      font-size: $fontSizeBase;
      font-family: $fontFamilyBase;
    }
  }
  input:focus {
    outline: none;
  }
  .invalid-message {
    color: #f37427;
    font-family: $fontFamilyBase;
    font-size: 12px;
    display: inline-block;
    position: absolute;
    top: calc(100% + 7px);
    padding-left: 20px;
    background: url("../../img/notification-error-orange.svg") no-repeat;
    background-size: contain;
  }
  label {
    color: rgba(0, 51, 153, 0.4);
    font-size: 12px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 0;
    top: 7px;
    transition: 0.2s ease all;
    font-family: $fontFamilyBase;
    font-weight: bold;
  }
  input:focus ~ label,
  &.not-empty input ~ label {
    top: -10px;
    font-size: 12px;
    color:$smalt;
  }
  //ie11 issue
  input:-webkit-autofill ~ label {
    top: -10px;
    font-size: 12px;
    color:$smalt;
  }
  .bar {
    position: relative;
    display: block;
    width: 100%;
  }
  .bar:before,
  .bar:after {
    content: "";
    height: 1px;
    width: 0;
    bottom: 0;
    position: absolute;
    background: $smalt;
    transition: 0.2s ease all;
  }
  .bar:before {
    left: 50%;
  }
  .bar:after {
    right: 50%;
  }
  input:focus ~ .bar:before,
  input:focus ~ .bar:after {
    width: 50%;
  }
  .highlight {
    position: absolute;
    width: 150px;
    top: 25%;
    left: 0;
    pointer-events: none;
  }

  input:focus ~ .highlight {
    animation: inputHighlighter 0.3s ease;
  }

  @keyframes inputHighlighter {
    from {
      background: #5264ae;
    }
    to {
      width: 0;
      background: transparent;
    }
  }
}

%styled-checkbox {
  position: relative;
  height: 33px;
  width: 70px;
  border-radius: 15px;
  background-color: rgba(0, 51, 153, 0.2);
  display: inline-block;
  cursor: pointer;
  input {
    position: fixed;
    z-index: -1;
    visibility: hidden;
    top: -9999px;
    & ~ span .active {
      display: none;
    }
    &:checked ~ span {
      left: 20px;
      .inactive {
        display: none;
      }
      .active {
        display: inline;
      }
    }
  }
  span {
    @extend %v-align;
    height: 25px;
    width: 45px;
    border-radius: 12px;
    background-color: $smalt;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 5px;
    color: $white;
    font-family: $fontFamilyBase;
    font-size: 12px;
    text-align: center;
    transition: left 0.2s ease;
    i {
      font-style: normal;
    }
  }
}

%normal-checkbox {
  display: inline-block;
  label span {
    display: inline-block;
    height: 25px;
    width: 25px;
    border: 1px solid $smalt;
    opacity: 0.5;
    border-radius: 4px;
    vertical-align: middle;
  }
  input {
    position: absolute;
    visibility: hidden;
    top: -9999px;
  }
  label ~ span {
    font-family: $fontFamilyBase;
    font-size: 12px;
    color: $smalt;
    display: inline-block;
    vertical-align: middle;
  }
  input:checked ~ span {
    opacity: 1;
    background: url("../../img/check-mark.svg") no-repeat center;
  }
}

%notification {
  height: 88px;
  width: 390px;
  border-radius: 1px;
  background-color: $white;
  box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.05), 0 7px 13px 0 rgba(0, 0, 0, 0.08);
  transform: translateX(100%);
  opacity: 0;
  transition: all 0.2s;
  display: flex;
  align-items: center;
  position: absolute;
  top: 15px;
  //IE11 supporting strange behaivor of position absolute and alignment
  left: 97.5vw;
  z-index: 13;
  @media (max-width: 450px) {
    transform: translateX(0) !important;
    left: 0;
    width: 100%;
  }
  &.active {
    transform: translateX(-100%);
    opacity: 1;
  }
  .color-margin {
    height: 88px;
    min-width: 50px;
    width: 50px;
    border-radius: 1px 0 0 1px;
    background: url("../../img/notification-error.svg") #f37427 center center
      no-repeat;
  }
  &.notice .color-margin {
    background: url("../../img/notification-notice.svg") $smalt center
      center no-repeat;
  }
  &.success .color-margin {
    background: url("../../img/notification-notice.svg") #66BB6A center
      center no-repeat;
  }
  &.sticky {
    position: fixed;
  }

  .notification-text {
    padding: 0 50px 0 14px;
    color: $textColor;
    font-family: $fontFamilyBase;
    font-size: 13px;
  }
  .notification-close {
    @extend %v-align;
    right: 20px;
    background: url("../../img/notification-close.svg") no-repeat;
    background-size: cover;
    width: 14px;
    height: 14px;
    min-height: 14px;
    padding: 0;
    border: none;
    cursor: pointer;

    @media (max-width: 450px) {
      width: 24px;
      height: 24px;
      background-size: 14px;
      background-position: center;
    }
  }
}

%select {
  position: relative;
  border-bottom: 1px solid #DFEAFF;
  background: $white url('../../img/select-arrow.svg');
  background-size: 12px 8px;
  background-repeat: no-repeat;
  background-position: 99% center;
  
  select {
    width: 100%;
    height: 57px;
    padding-right: 20px;
    background-color: transparent;
    border:none;
    border-radius: 0;
    font:400 13px $EMRegular;
    line-height: 57px;
    color:$textColor;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    &::-ms-expand {
      display: none;
    }

    &:focus {
      outline: none;
    }
    &:focus + label,
    &.not-empty + label {
      color:$smalt;
    }
  }
}

%styled-radiobox {
  input[type=radio] {
    position: absolute;
    left: -9999px;

    + label {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      width: 110px;
      height: 95px;
      font:400 13px $fontFamilyBase;
      color:$textColor;
      text-align: center;
      background-color: $white;
      border: 1px solid #DFEAFF;
      border-radius: 6px;
      opacity: 0.5;
      transition: opacity .2s ease-in-out, box-shadow .2s ease-in-out;

      img {
        margin-top: -10px;
      }

      .label-text {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 10px;
      }
    }

    &:checked + label {
      box-shadow: 0 7px 13px 0 rgba(0,0,0,0.08);
      border-color:#2369BD;
      opacity: 1;
    }
  }
}

%textarea {
  width: 100%;
  height: 140px;
  resize: none;
  margin-bottom: 50px;
  border:none;
  border-bottom: 1px solid #DFEAFF;
  color: $textColor;
  font-family: $EMRegular;
  font-size: 13px;
  line-height: $lineHeightBase;

  &::placeholder {
    color: rgba(0,33,100,0.5);
  }

  &:focus {
    outline: none;
  }
  &:focus + label,
  &.not-empty + label {
    color:$smalt;
  }
}

%close-modal {
  width: 20px;
  height: 20px;
  @extend %btn-reset;
  position: absolute;
  top: 20px;
  right: 20px;

  &:after {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    @extend %full-align;
    background: rgba(0,51,153,0.15) url("../../img/notification-close.svg") no-repeat;
    background-size: 9px;
    background-position: center;
    border-radius: 50%;
  }

  &:focus,
  &:active {
    outline:none;
  }

  @media #{$mq-mobile} {
    width: 30px;
    height: 30px;
  }
}

%modal-ok-button {
  height: 50px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  cursor: pointer;
  border: none;
  background-color: rgba(0, 51, 153, 1);
  box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.05), 0 7px 13px 0 rgba(0, 0, 0, 0.08);
  color: rgba(255, 255, 255, 1);
  font-family: $EMSemi;
  font-size: 13px;
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

%proceed-button {
  outline: none;
  width: 100%;
  background-color: $smalt;
  padding: 15px;
  color: $white;
  font-weight: normal;
  font-family: #{$EMSemi};
  font-size: 13px;
  line-height: 15px;
  cursor: pointer;
  transition: background-color 0.3s;
  position: absolute;
  bottom: 0;
  left: 0;
  &:hover,
  &:active {
    background-color: darken($smalt, 2);
  }
  &[disabled] {
    cursor: default;
    background-color: rgba(0, 51, 153, 0.5);
  }
}

// shared styles for info pages (t&c, privacy etc.)
%info-wrapper {
  background-color: #f0f0f0;
}

%info-container {
  @extend %container;
  max-width: 1000px;
  padding: 60px 0;
  font-family: #{$EMRegular};
  color: $sapphire;
  @media #{$mq-tablet} {
    padding: 60px 20px;
  }
}

%info-inner {
  background-color: $white;
  padding: 40px;
}

%info-list {
  @extend %pale-text;
  padding-left: 16px;
  & > li {
    list-style-type: disc;
  }
}

%info-link {
  &:link,
  &:visited {
    @extend %pale-text;
    text-decoration: underline;
  }
  &:hover,
  &:active {
    cursor: pointer;
    color: rgba(0, 33, 100, 0.7);
  }
}

%info-h1 {
  @extend %esso-title;
  font-size: 36px;
  line-height: 45px;
  word-wrap: break-word;

  @media #{$mq-tablet} {
    font-size: 32px;
    line-height: 1.25;
  }
}

%info-tip {
  color: rgba(0, 51, 153, 0.5);
  font-size: 13px;
  line-height: 15px;
  margin: 0;
}

%info-h2 {
  font-weight: normal;
  font-size: 28px;
  line-height: 34px;
  margin: 60px 0 30px;
}

%info-h3 {
  font-weight: normal;
  font-size: 18px;
  line-height: 16px;
  margin: 30px 0 15px;
}

%info-button {
  @extend %blue-btn;
  width: 178px;
  margin: 11px 0 21px;
}

%white-blue-text {
  color: rgba(0,51,153,0.3);
  font-family: $fontFamilyBase;
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 11px;
  text-transform: uppercase;
}
