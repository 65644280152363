@import '../../assets/scss/_shared.scss';

.info-modal {
  @extend %full-align;
  width: 467px;
  max-width: 90%;
  padding: 30px;
  background-color: #fff;
  max-height: 90%;
  overflow-y: auto;

  .close-modal-button {
    @extend %close-modal;
  }

  h2 {
    padding-right: 40px;
    font-size: 18px;
    font-family: $EMSemi;
    margin-top: 0;
    margin-bottom: 20px;
    color:#002164;
  }

  p {
    color: rgba(0, 33, 100, 0.7);
    font-size: 14px;
    line-height: 1.286;
    margin: 0 0 20px;
  }

  .modal-bottom {
    padding-top: 23px;
    border-top: 1px solid #dfeaff;
    p {
      font-size: 12px;
      line-height: 1.5;
      margin-bottom: 0;
    }
    a {
      color: #003399;
      text-decoration: underline;
    }
  }
}