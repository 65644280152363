@import "../../assets/scss/_shared.scss";

.login-container {
  @extend %container;
  max-width: 100%;
  display: flex;
  background-color: #f9f9f9;
}

.auth-banner {
  position: relative;
  min-height: 760px;
  width: 41%;
  // flex: 6;
  
  @media (max-width: 1100px) {
    display: none;
  }
  .text-container {
    @extend %full-align;
  }
  h2 {
    @extend %esso-title;
    color: #fff;
    font-size: 28px;
  }
  a {
    @extend %blue-btn;
    margin-top: 40px;
  }
}

.phone-input-wrapper {
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
  align-items: center;
  position: relative;
  .phone-prefix {
    display: block;
    min-width: 63px;
    border-radius: 4px;
    background-color: #003399;
    padding: 5px 10px;
    color: #fff;
    margin-right: 10px;
    font-family: Arial;
    font-size: 13px;
    font-weight: bold;
    line-height: 20px;
    text-align: center;
    position: relative;
    top: 10px;
  }
  .field-name {
    color: rgba(0, 51, 153, 0.4);
    font-size: 12px;
    font-weight: normal;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    left: 0;
    top: 7px;
    transition: 0.2s ease all;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
  }

  .phone-field {
    width: 100%;
  }

  .material-input {
    width: 100%;

    label,
    input:focus ~ label,
    &.not-empty input ~ label {
      left: -73px;
      top: -15px;
    }
  }
}

.second-form {
  h1 {
    margin-bottom: 42px !important;
  }
  p {
    color: #002164;
    font-family: Arial;
    font-size: 13px !important;
    margin-bottom: 10px !important;
  }
  .user-email {
    font-weight: 700;
  }
  .user-phone {
    color: rgba(0, 51, 153, 0.5);
  }
  .contact-link {
    color: rgba(0, 51, 153, 0.5);
    font-family: Arial;
    font-size: 13px !important;
    line-height: 15px;
    text-align: right;
    margin-bottom: 0;
    a {
      margin-top: 0;
      color: #003399;
    }
  }
  .login-button {
    text-decoration: none;
    @extend %blue-btn;
    margin-top: 30px !important;
  }
}

.login-forms-container {
  position: relative;
  z-index: 1;
  width: 59%;
  // flex: 5;
  display: flex;
  align-items: center;
  @media (max-width: 1100px) {
    margin: 50px 0;
    width: 100%;
  }
  .login-forms-wrapper {
    margin-left: 16%;
    width: 100%;
    max-width: 467px;
    @media (max-width: 1100px) {
      position: relative;
      transform: none;
      top: 0;
      left: 0;
      margin: 0 auto;
    }
  }
  a {
    color: #003399;
    text-decoration: underline;
  }

  .input-link {
    display: inline-block;
    color: #002164;
    text-decoration: underline;
    cursor: pointer;
  }

  h1 {
    @extend %esso-title;
    font-size: 42px;
    margin-bottom: 0;
  }

  h2 {
    @extend %esso-title;
    font-size: 28px;
  }

  p {
    @extend %pale-text;
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 34px;

    &.no-indent {
      margin-bottom: 0;
    }
  }
  .login-check-btn {
    @extend %blue-btn;
    margin-top: 0;
    padding: 15px 45px;
    font-size: 13px;
  }
  .login-notice {
    font-size: 13px;
    margin-top: 20px;
    font-family: Arial, Helvetica, sans-serif;
  }
  input {
    width: 100%;
  }
}
.temp-notice {
  float: right;
}
.login-check-btn {
  @extend %blue-btn;
  margin-top: 20px;
}

.register-content-block {
  width: 100%;
  max-width: 590px;
  min-height: 500px;
  .register-content-inner {
    border-radius: 2px;
    background-color: #ffffff;
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.02);
    padding: 40px;
    margin-bottom: 20px;
    @media (max-width: 475px) {
      padding: 30px 20px 35px;
    }
  }
  h2 {
    margin-top: 0;
  }
  h3 {
    color: #002164;
    font-family: $EMSemi;
    font-size: 14px;
    margin-top: 0;
    margin-bottom: 5px;
  }
  .register-btn {
    width: 100%;
    margin-top: 20px !important;
  }
  p {
    @extend %pale-text;
    color: #859ece;
    margin-bottom: 0;
  }
  .input-link {
    @extend %pale-text;
    color: #003399;
    text-decoration: underline;
    cursor: pointer;
  }
  .register-info-text {
    font-size: 13px;
    color: #9bafd7;
    margin: 5px 0 15px;
  }
  a {
    color: #003399;
    text-decoration: underline;
    opacity: 1;
  }
}

.registration-wrapper {
  @extend %container;
  margin: 50px auto;
  max-width: 1200px;
  overflow: hidden;

  .given-surname {
    display: flex;
    justify-content: space-between;
    .material-input {
      flex: 50%;
      max-width: 47%;
      margin-bottom: 0;
    }
  }
  form {
    display: flex;
    justify-content: space-between;

    &.register-content-block {
      display: block;
    }
    @media (max-width: 800px) {
      flex-wrap: wrap;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    & > div {
      max-width: 590px;
      @media (max-width: 800px) {
        width: 100%;
      }
    }
  }
  h2 {
    @extend %esso-title;
    font-size: 28px;
  }
  .register-btn {
    @extend %blue-btn;
    margin-top: 50px;
    padding: 15px 45px;
    font-size: 13px;
    &.register-disabled {
      cursor: not-allowed;
      background-color: rgba(0, 51, 153, 0.5);
      outline: none;
      border: none;
    }
  }
}

.switch-label {
  @extend %small-text;
  color: rgba(0, 51, 153, 0.5);
  display: flex;
  justify-content: space-between;
  align-items: center;
  span {
    line-height: 1.5;
    max-width: 75%;
    color: inherit;
  }
  
  .styled-checkbox{
    span{
      color: #fff;
    }
  }
}

.label-styled {
  width: 421px;
  max-width: 100%;
  color: rgba(0, 51, 153, 0.5);
  font-family: Arial;
  font-size: 12px;
  line-height: 1.6;
  margin: 20px 0 15px;
  a {
    color: #003399;
    text-decoration: underline;
  }
  /* & ~ fieldset {
    margin-top: 35px;
  } */
}
.text-styled {
  color: $textColor;
  font-family: #{$EMRegular};
  font-size: 13px;
  line-height: 15px;
  &:first-of-type {
    margin-top: 53px;
  }
  a {
    color: #003399;
    text-decoration: underline;
  }
}
.reg-divider {
  box-sizing: border-box;
  border: 0.5px solid #dfeaff;
  margin: 30px 0 0;
  &.inner-divider {
    margin: 35px 0;
  }
}

fieldset {
  padding: 0 !important;
}
.normal-checkbox {
  @extend %normal-checkbox;
  margin-right: 30px;
  .checkbox-text {
    margin-left: 10px;
  }
}

.reg-options-switch {
  display: flex;
  justify-content: space-between;
  max-width: 375px;
  margin: 30px 0 50px 0;
}

.reg-option {
  height: 100px;
  width: 110px;
  background-color: $white;
  box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.05), 0 7px 13px 0 rgba(0, 0, 0, 0.08);
  display: inline-block;
  position: relative;
  cursor: pointer;
  @media (max-width: 425px) {
    width: 85px;
  }
  &-inner {
    @extend %full-align;
    @extend %small-text;
    text-align: center;
    width: 100%;
    padding: 10px;
    opacity: 0.5;
  }
  &.active &-inner {
    opacity: 1;
    transition: all 0.2s;
  }
  img {
    display: block;
    margin: 0 auto;
  }
  span {
    display: inline-block;
    margin-top: 15px;
    word-wrap: break-word;
    max-width: 100%;
    color: inherit;
  }
}

.gender-switch {
  margin: 25px 0;
  & > label {
    width: 90px;
    height: 80px;
    margin-right: 15px;
  }

  input[type="radio"] {
    position: absolute;
    left: -9999px;

    &:checked {
      + label .reg-option-inner {
        opacity: 1;
        transition: all 0.2s;
      }
    }
  }
}

.more-info-title {
  color: rgba(0, 33, 100, 0.5);
  font-family: Arial;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  justify-content: space-between;
  flex-flow: nowrap;
  align-items: center;
  margin-top: 10px;
  cursor: pointer;
  outline: 0;
  p {
    margin: 0;
  }
  img {
    width: 17px;
    height: 17px;
  }
  &--closed {
    img.minus-icon {
      display: none;
    }
    margin-bottom: -25px;
  }

  .open-indicator-wrapper {
    position: relative;
    display: inline-block;
    width: 17px;
    height: 17px;
    border: 1.43px solid rgba(0, 33, 100, 0.5);
    border-radius: 50%;
    i {
      display: inline-block;
      @extend %full-align;
    }
  }
}
.more-info-title:not(.more-info-title--closed) {
  img.plus-icon {
    display: none;
  }
}
.more-info-text {
  padding: 10px;
  // display: block;
  -webkit-animation: fadein 0.35s ease-in;
  animation: fadein 0.35s ease-in;
  .text-styled {
    margin-top: 10px;
  }
}

.material-input {
  @extend %material-input;

  .eye-indicator {
    position: relative;
    height: 10px;
    width: 18px;
    margin-left: 15px;
    background: url("../../img/eye-icon.svg") no-repeat;
    cursor: pointer;
    border: none;
    outline: none;
    padding: revert;

    &:after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 23px;
      height: 23px;
      border-radius: 50%;
      background-color: rgba(0, 51, 153, 0.1);
      opacity: 0;
      visibility: hidden;
    }

    &.active {
      background-image: url("../../img/eye-icon-active.svg");
      &:after {
        opacity: 1;
        visibility: visible;
      }
    }

    @media #{$mq-mobile} {
      width: 30px;
      height: 20px;
      margin-left: 10px;
      background-position: center;
    }
  }

  .input-link {
    display: inline-block;
    padding: 6px 16px;
    border: 1px solid #002164;
    border-radius: 20px;
    color: #002164;
    font-family: Arial;
    font-size: 11px;
    text-decoration: none;
    white-space: nowrap;
    margin-left: 15px;
    cursor: pointer;
  }

  input[name="card-num"] {
    color: $sapphire;
    font-weight: bold;
  }
}

.styled-checkbox {
  @extend %styled-checkbox;
}

.notification-wrapper {
  @extend %notification;
}

.forgot-email-wrapper {
  button {
    @extend %blue-btn;
    margin-top: 40px;
  }
  .login-notice {
    float: right;
  }

  .contact-link {
    .login-notice {
      float: none;
    }
  }
}

.confirm-email-wrapper {
  @extend %container;
  text-align: center;
  margin: 100px auto 125px;
  .confirm-content-wrapper {
    max-width: 420px;
    margin: 0 auto;
    img{
      width: auto;
      height: auto;
    }
  }
  h1 {
    @extend %esso-title;
    font-family: $EMSemi;
  }
  p {
    @extend %pale-text;
    font-size: 18px;
    font-family: Arial, Helvetica, sans-serif;
    color: rgba(0, 33, 100, 0.5);
  }
  .small-description {
    font-size: 13px;
    a {
      color: #003399;
      text-decoration: underline;
    }
  }
  .link-button {
    @extend %blue-btn;
    margin-top: 25px;
  }
}

.help-block-wrapper {
  @extend %container;
  margin: 75px auto 50px;
  background-color: #fff;
  padding: 50px;
  display: flex;
  justify-content: space-between;
  text-align: left;
  div {
    max-width: 400px;
  }
  p {
    font-size: 14px;
  }
  a {
    align-self: center;
    margin: 0 !important;
  }
}

.WhyModal {
  @extend %full-align;
  max-width: 400px;
  padding: 30px 50px 60px;
  background-color: #fff;
  position: relative;
  text-align: center;
  @media #{$mq-mobile} {
    position: absolute;
    max-width: 100vw;
    width: 100vw;
  }
  .close-modal-button {
    @extend %close-modal;
  }
  img {
    margin: 30px 0 35px;
  }
  h2 {
    color: #002164;
    font-family: $EMSemi;
    font-size: 18px;
    line-height: 1.4;
    text-align: center;
  }
  i {
    font-style: italic;
    opacity: 0.5;
  }
  p {
    color: #002164;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 13px;
    line-height: 1.6;
  }
  .small-caps {
    margin-top: 20px;
    text-align: center;
    color: rgba(0, 51, 153, 0.5);
    a {
      color: rgba(0, 51, 153, 1);
    }
  }
  .modal-ok-button {
    @extend %modal-ok-button;
    padding: 16px;
  }
  .box-input {
    max-width: 100%;
  }
}

.forgot-modal-content {
  padding-bottom: 50px;
}
.terms-modal-content {
  margin-bottom: 45px;
}

.auth-tabs {
  margin-bottom: 12px;
  margin-top: 25px;
  margin-left: -17px;
  .tabs-item {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    min-width: 115px;
    font-size: 16px;
    font-family: $EMSemi;
    text-align: center;

    &:first-child {
      position: relative;
      .tab-btn {
        &:after {
          content: "";
          position: absolute;
          z-index: -1;
          background-color: #003399;
          top: 0;
          bottom: 0;
          left: 0;
          transform: translateX(100%);
          width: 100%;
          border-radius: 20px;
          transition: transform 0.3s ease-in-out;
        }

        &.active {
          &:after {
            transform: translateX(0);
          }
        }
      }
    }
  }

  .tab-btn {
    padding: 10px 15px;
    color: rgba(0, 33, 100, 0.5);
    cursor: pointer;
    transition: color 0.3s ease-in-out;
    -webkit-tap-highlight-color: transparent;
    &.active {
      border-radius: 20px;
      color: #fff;
    }
  }
}

.registration-links {
  margin-bottom: 21px;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.02);

  li {
    &:last-child {
      .registration-way {
        border-top: 1px solid rgba(0, 51, 153, 0.05);

        &:after {
          border-bottom-left-radius: 2px;
          border-bottom-right-radius: 2px;
        }

        &:hover {
          border-top-color: transparent;
        }
      }
    }

    &:first-child {
      .registration-way {
        &:after {
          border-top-left-radius: 2px;
          border-top-right-radius: 2px;
        }
      }
    }
  }
}

a.registration-way {
  display: block;
  position: relative;
  z-index: 0;
  text-decoration: none;
  padding: 26px 80px 26px 30px;
  font-size: 14px;
  color: rgba(0, 33, 100, 0.7);

  &:before {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 30px;
    width: 14px;
    height: 15px;
    background-image: url("../../img/arrow-blue.svg");
    background-repeat: no-repeat;
  }

  &:after {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    height: 100%;
    left: 50%;
    width: 100%;
    transform: translateX(-50%);
    background-color: #fff;
    transition: all 0.3s ease-in-out;
  }

  &:hover {
    z-index: 1;
    color: #002164;
    &:after {
      width: 103.2%;
      height: 101%;
      box-shadow: 0 6px 10px rgba(0, 0, 0, 0.02);
    }
  }
}

.registration-modal-btn {
  text-align: center;

  .inner-link {
    position: relative;
    display: inline-block;
    padding-right: 34px;
    font-size: 14px;
    font-family: $EMRegular;
    color: rgba(0, 51, 153, 0.5);

    cursor: pointer;

    &:after {
      content: "?";
      position: absolute;
      right: 0;
      top: calc(50% - 10px);
      width: 19px;
      height: 19px;
      line-height: 19px;
      background-color: #003399;
      border-radius: 50%;
      color: #fff;
      font-weight: 600;
      font-size: 11px;
    }
  }
}

.cards-description-modal {
  @extend %full-align;
  width: 467px;
  max-width: 100%;
  padding: 30px;
  background-color: #fff;

  .close-modal-button {
    @extend %close-modal;
  }

  h2 {
    font-size: 18px;
    font-family: $EMSemi;
    margin-top: 0;
    margin-bottom: 20px;
    color: #003399;
  }

  p {
    color: rgba(0, 33, 100, 0.7);
    font-size: 14px;
    line-height: 1.286;
    margin: 0 0 20px;
  }

  .modal-bottom {
    padding-top: 23px;
    border-top: 1px solid #dfeaff;
    p {
      font-size: 12px;
      line-height: 1.5;
    }
  }

  .cards-list {
    li {
      padding-bottom: 20px;
      font-size: 14px;
      font-family: $EMRegular;
      color: rgba(0, 33, 100, 0.7);

      &:last-child {
        padding-bottom: 0;
      }

      img {
        vertical-align: middle;
        margin-right: 15px;
      }
    }
  }
}

@keyframes TabsAppearing {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.registation-tab {
  animation: TabsAppearing 0.2s ease-in-out;
}

//WEBVIEW FOR CHANGE PHONENUMBER
.change-phone-page {
  #root,
  .internal_profile-wrapper {
    overflow: visible;
  }
}


.weak-password-modal {
  padding-top: 18px;
  .modal-icon {
    margin-bottom: 14px;
  }
  h2 {
    font-size: 22px;
  }
  .info-field {
    margin-bottom: 30px;
    padding-bottom: 15px;
    border-bottom: 1px solid transparentize($smalt, .95);
    font-size: 12px;
    .marked-value {
      font-size: 13px;
      color: $sapphire;
      padding-top: 4px;
    }
  }
}

.change-weak-password {
  .notice-link {
    color: $smalt;
    text-decoration: underline;
    cursor: pointer;

    &.disabled {
      cursor: default;
      opacity: .5;
      text-decoration: none;
      pointer-events: none;
    }
  }
}

.grecaptcha-badge {
  display: none !important;
}