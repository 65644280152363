@import '../../../assets/scss/shared';

header {
	margin: 0 auto;
	// border-bottom: solid 10px #003399;
}
.details-header {
	text-align: center;
}

.cookies-wrapper {
	background-color: #002164;
	width: 100%;
	padding: 0 30px;
	z-index: 10;
	overflow: hidden;
	position: fixed;
	transition-property: all;
	transition-duration: 0.5s;
	transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
	transform: scaleY(0);
	transform-origin: top;
	box-shadow: 0 5px 14px 5px rgba(0, 0, 0, 0.1);
	&.open {
		transform-origin: top;
		transform: scaleY(1);
		transition-property: all;
		transition-duration: 0.5s;
		transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
	}
	.cookies-inner {
		@extend %container;
		color: #fff;
		padding: 30px 0;
		display: flex;
		flex-wrap: wrap;
		@media (max-width: 600px) {
			display: block;
		}
	}
	h2 {
		@extend %bold-title;
		color: #fff;
	}
	p {
		@extend %esso-text;
	}
	button {
		@extend %white-btn;
		padding: 16px 45px;
		background-color: #fff;
		color: #002164;
		cursor: pointer;
	}
	a {
		@extend %link-arrow;
		color: #fff;
		&:after {
			background: url('../../../img/link-arrow-white.svg') no-repeat;
		}
		&:hover {
			color: #fff;
			text-decoration: underline;
		}
	}
	.cookies-text {
		width: calc(100% - 300px);
		@media (max-width: 900px) {
			width: calc(100% - 220px);
		}
		@media (max-width: 600px) {
			width: 100%;
		}
	}
	.cookies-cta {
		padding-top: 30px;
		margin-left: auto;
		position: relative;
	}
}

.top-nav {
	background-color: #f0f0f0;
	&::after {
		content: '';
		display: block;
		clear: both;
	}
	&-inner {
		@extend %container;
		max-width: 1440px;
		padding: 0 40px;
		overflow: visible;
		@media #{$mq-mobile} {
			display: flex;
			flex-direction: column;
			text-align: center;
			justify-content: center;
			padding: 0 10px;
		}
		&:after {
			content: '';
			clear: both;
			display: table;
		}
	}
	& a {
		color: #454545;
		font-family: $EMSemi;
		font-size: 13px;
		line-height: 14px;
	}
	& .top-left-nav {
		float: left;
		padding: 13px 0;

		& a:not(:last-child)::after {
			content: '';
			display: inline-block;
			margin: 0 18px;
			height: 6px;
			width: 6px;
			border-radius: 50%;
			background-color: #d8d8d8;
		}
	}
	& .top-right-nav {
		float: right;
		// padding: 16px 0;
		@extend %flex-align;

		& .separator {
			display: inline-block;
			vertical-align: middle;
			height: 13px;
			width: 2px;
			border: 1px solid #828282;
			margin: 0 18px;
		}
		& .not-active {
			opacity: 0.2;
		}
	}
	& .top-right-select {
		@extend %flex-align;

		display: inline-flex;
		position: relative;
		padding: 14px 20px;
		cursor: pointer;

		&.active {
			background-color: #e0e0e0;
		}
		&:hover {
			background-color: #e0e0e0;
			transition: 0.3s;
		}
		& .header-dropdown {
			position: absolute;
			top: 100%;
			right: 0;
			z-index: 11;
			border-radius: 2px;
			background-color: #ffffff;
			box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.05), 0 7px 13px 0 rgba(0, 0, 0, 0.08);
			max-height: 0;
			transition: 0.2s;
			overflow: hidden;
			&.active {
				max-height: 150px;
				transition: 0.2s;
			}
			& .dropdown-item {
				position: relative;
				display: block;
				padding: 30px;
				width: 220px;
				&:hover {
					background-color: #e0e0e0;
					transition: 0.3s;
				}
				&:first-child {
					&:after {
						position: absolute;
						content: '';
						bottom: 0;
						right: 30px;
						width: 161px;
						height: 2px;
						background-color: #dfeaff;
						opacity: 0.4;
					}
				}
			}
		}
	}
	& .country-select-link {
		display: inline-block;
		padding: 3px 0 2px 28px;
	}
}
.nav-item-overflow {
	z-index: -1;
	position: absolute;
	top: 52px;
	height: 100vh;
	width: 100%;
	background-color: rgba(0, 0, 0, 0.7);
	opacity: 0;
	@media #{$mq-mobile} {
		top: 102px;
	}
	//&.active {
	//	transition: opacity 0.3s;
	//	opacity: 1;
	//	z-index: 3;
	//}
	&.active > .top-right-select {
		background: #e0e0e0;
	}
}
.belg-langs {
	max-width: 0;
	transition: 0.5s;
	white-space: nowrap;
	overflow: hidden;
	&.active {
		transition: 0.5s;
		max-width: 81px;
	}
	& a {
		opacity: 0.5;
	}
	& a.active {
		opacity: 1;
	}
}
.main-nav {
	height: 100px;
	background-color: #fff;
	border-bottom: solid 10px #003399;

	&-inner {
		height: 90px;
		@extend %container;
		max-width: 1440px;
		padding: 0 40px;
		@extend %flex-align;
		overflow: visible;
		@media #{$mq-mobile} {
			padding: 0 20px;
		}
	}
	& a {
		color: #454545;
		font-family: $EMSemi;
		font-size: 16px;
		&.active {
			color: #003399;
		}
	}
	& nav {
		width: 100%;
		text-align: right;
		margin-right: 20px;
		overflow: hidden;
		@media (max-width: 1024px) {
			padding: 90px 25px;
			will-change: auto;
			box-shadow: 0 5px 14px 0 rgba(0, 0, 0, 0.1);
			position: absolute;
			top: 100%;

			text-align: left;
			right: 0;
			overflow: hidden;
			margin: 0;
			z-index: 2;
			transform-origin: right top;
			transform: scale(0);
			background: url('/img/esso-2d.svg') #fff no-repeat 25px 25px;
			transition: transform 0.3s ease-in-out, width 0.3s ease-in-out;
			width: 300px;
			&.active {
				z-index: 4;
				transform-origin: right top;
				transform: scale(1);
				transition: transform 0.3s ease-in-out, width 0.3s ease-in-out;
			}
			a {
				display: block;
				padding: 25px 0;
				margin: 0;
				width: 100%;
				border-top: 1px solid #e5e5e5;
				background: url('/img/menu-arrow.svg') no-repeat center right;
			}
			a:last-child {
				border-bottom: 1px solid #e5e5e5;
			}
		}
		& a:not(:last-child) {
			margin-right: 40px;
			display: inline-block;
			@media #{$mq-desktop} {
				margin-right: 20px;
			}
		}
	}
	.nav-buttons {
		display: flex;
		margin-left: auto;
		align-items: center;
	}
	& .main-nav-search {
		width: 18px;
		height: 20px;
		background: url('../../../img/search-icon.svg') no-repeat;
		border: none;
		cursor: pointer;
		padding: 0;
		padding-right: 20px;
	}
	.main-nav-cart {
		width: 18px;
		height: 24px;
		border: none;
		cursor: pointer;
		background: url('../../../img/shoppingbag.svg') no-repeat;
		margin-left: 20px;
		padding-right: 10px;
	}
	&-logo {
		height: 57px;
	}
	&-logo-after {
		display: inline-block;
		padding-left: 20px;
		margin-left: 20px;
		position: relative;
		font-family: #{$EMRegular};
		color: rgba(69, 69, 69, 0.5);
		line-height: 36px;
		font-size: 29px;
		&:before {
			position: absolute;
			content: '';
			top: 0;
			left: 0;
			height: 40px;
			width: 1px;
			background-color: rgba(69, 69, 69, 0.5);
		}
	}
}

/* HamButton */
.hamburger-button {
	cursor: pointer;
	width: 25px;
	height: 20px;
	margin: auto;
	margin-left: 20px;
	@media (min-width: 1025px) {
		display: none;
	}
	i {
		background-color: rgba(69, 69, 69, 1);
		border-radius: 3px;
		content: '';
		display: block;
		width: 100%;
		height: 3px;
	}
	i:nth-child(1) {
		animation: outT 0.3s backwards;
		animation-direction: reverse;
	}
	i:nth-child(2) {
		margin: 5px 0;
		animation: outM 0.3s backwards;
		animation-direction: reverse;
	}
	i:nth-child(3) {
		animation: outBtm 0.3s backwards;
		animation-direction: reverse;
	}
	&.active {
		i:nth-child(1) {
			animation: inT 0.3s forwards;
		}
		i:nth-child(2) {
			animation: inM 0.3s forwards;
		}
		i:nth-child(3) {
			animation: inBtm 0.3s forwards;
		}
	}
}

@keyframes inM {
	50% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(45deg);
	}
}

@keyframes outM {
	50% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(45deg);
	}
}

@keyframes inT {
	0% {
		transform: translateY(0px) rotate(0deg);
	}
	50% {
		transform: translateY(8px) rotate(0deg);
	}
	100% {
		transform: translateY(8px) rotate(135deg);
	}
}

@keyframes outT {
	0% {
		transform: translateY(0px) rotate(0deg);
	}
	50% {
		transform: translateY(8px) rotate(0deg);
	}
	100% {
		transform: translateY(8px) rotate(135deg);
	}
}

@keyframes inBtm {
	0% {
		transform: translateY(0px) rotate(0deg);
	}
	50% {
		transform: translateY(-8px) rotate(0deg);
	}
	100% {
		transform: translateY(-8px) rotate(135deg);
	}
}

@keyframes outBtm {
	0% {
		transform: translateY(0px) rotate(0deg);
	}
	50% {
		transform: translateY(-8px) rotate(0deg);
	}
	100% {
		transform: translateY(-8px) rotate(135deg);
	}
}

.mobile-item-arr {
	@extend %v-align;
	@media (max-width: 875px) {
		display: block;
	}
}

.menu_nav {
	// z-index: 10 !important;
	a {
		position: relative;
	}
	.menu-logo {
		display: none;
		position: absolute;
		top: 15px;
		width: 80px;
		height: 57px;
		@media (max-width: 1024px) {
			display: block;
		}
	}
}
.nav_change-view {
	&.hiddenBlock {
		visibility: hidden;
		opacity: 0;
	}

	@media (max-width: 875px) {
		display: none !important;
	}
}

.mobile-item-arr {
	display: none;
	@extend %v-align;
	right: 20px;
	@media (max-width: 875px) {
		display: block;
	}
}

.main-logo-link {
	@extend %flex-align;
}
