@import "./shared";
@import "./typography";

* {
  box-sizing: border-box;
}

body {
  font-family: $fontFamilyBase;
  color: rgba($sapphire, 0.5);
  font-size: $fontSizeBase;
  line-height: $lineHeightBase;
  background-color: $mainBgColor;
  min-width: 320px;
  -webkit-font-smoothing: antialiased;

  &.ReactModal__Body--open {
    overflow: hidden;

    #root{
      top: 0;
      left: 0;
      width: 100%;
    }
  }
}

fieldset {
  border: none;
  padding: 0;
  margin: 0;
}

main {
  position: relative;
}

.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.7) !important;
  z-index: 11;
  @media all and (max-height: 380px) {
    overflow: scroll;
    .ReactModal__Content {
      top: 305px;
    }
  }
}

#root {
  overflow: hidden;
}

.container {
  @extend %container;
}

.bold-placeholder {
  position: absolute;
  left: 0;
  font-size: 14px;
  font-family: Arial, Helvetica, sans-serif;
  color: #002164;
  font-weight: bold;
  top: 7px;
}

#nprogress .bar {
  background: #003399;
  height: 4px;
}

#nprogress .spinner-icon {
  border-top-color: #003399;
  border-left-color: #003399;
  width: 26px;
  height: 26px;
  border-width: 3px
}

#nprogress .peg{
  display: none;
}

.loading-placeholder {
  display: none;
}

.nprogress-busy {
  .loading-placeholder {
    display: inline-block;
  }
  .onload-element {
    display: none;
  }
}

.ReactModal__Content {
  &:focus {
    outline: none;
  }
}

html.webview {
  .usabilla_live_button_container {
    display: none !important;
  }
}

.content-container-fluid {
  @extend %section-mb;

  padding: 90px 0;

  @media #{$mq-tablet} {
    padding: 60px 0;
  }
}