@import "./shared";

h1,
h2 {
  color: $sapphire;
  font-weight: normal;
  font-family: $EMSemi;
  font-size: 36px;
  line-height: 1.25;
  margin: 0 0 20px;

  @media #{$mq-tablet} {
    font-size: 32px;
  }
}

h2 {
  font-weight: normal;
  font-family: $EMRegular;
}

h3 {
  color: $sapphire;
  font-weight: normal;
  font-family: $EMSemi;
  font-size: 18px;
  line-height: 22px;
  margin: 0 0 20px;
}

a {
  transition: $transitionColor;
  text-decoration: none;
}

ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}

.heading {
  font-family: $EMSemi;
  font-size: 28px;
  margin: 0 0 20px;

  @media #{$mq-tablet} {
    font-size: 24px;
  }
}

.sub-title {
  display: block;
  color: $sapphire;
  font-family: $EMSemi;
  font-size: 22px;
  line-height: $lineHeightBase;
  margin: 0 0 8px;
}

.c-pointer {
  cursor: pointer;
}
