@import '../../assets/scss/_shared.scss';

@keyframes fromBottom {
  from {
    transform: translateY(50px);
    opacity:0;
  }
  to {
    transform: translateY(0);
    opacity:1;
  }
}

@keyframes fromTop {
  from {
    transform: translateY(calc(-100% + 20px));
    opacity:0;
  }
  to {
    transform: translateY(calc(-100% - 50px));
    opacity:1;
  }
}

.dialog-parent {
  position: relative;

  &.top-dialog {
    .dialog-window {
      &.opened {
        .dialog-content {
          animation:fromTop .2s ease-in-out;
          transform: translateY(calc(-100% - 50px));
        }
      }
    }
  }
}

.dialog-window {
  position: absolute;
  top:calc(100% + 10px);
  z-index: 3;

  &.opened {
    .dialog-content {
      animation:fromBottom .2s ease-in-out;
    }
  }
}

.dialog-content {

  padding: 40px 30px;
  background-color: #fff;
  box-shadow: 0 5px 14px 0 rgba(0,0,0,0.1);

  .close-modal-button {
    @extend %close-modal;
  }

  h2 {
    font-size: 18px;
    font-family: $EMSemi;
    color:#003399;
    margin:0 0 15px;
    padding: 0;
  }

  p {
    font-size: 13px;
    line-height: 1.5;
    color: #002164;
    margin: 0 0 20px;

    &.center {
      text-align: center;
    }

    &.small-caps {
      color: rgba(0,51,153,0.5);
      a {
        color:#003399;
        text-decoration: underline;
      }
    }
  }

  .call-link {
    font-size: 14px;
    font-family: $EMSemi;
    color: rgba(0,33,100,0.75);

    img {
      width: 30px;
      margin-right: 17px;
      vertical-align: middle;
    }

    span {
      vertical-align: middle;
    }

    a {
      color: rgba(0,33,100,0.75);
      text-decoration: none;
    }

    .send-email {
      display: block;
      background: url('../../img/arrow-blue.svg') no-repeat;
      background-size: 14px 15px;
      background-position: 100% 50%;
    }
  }

  hr {
    margin: 20px 0;
    border:none;
    border-top: 1px solid rgba(0,51,153,0.05);
  }
}

html.webview {
  .dialog-content {
    max-height: 60vh;
    overflow-y: auto;
  }
}

/* PHONE PREFIX DIALOG */

.phone-prefix-wrapper {
 	.dialog-window {
		top:calc(100% + 20px);
  }
  .dialog-content {
    width: 280px;
    max-width: 100%;
    .phone-prefix {
      cursor: pointer;
      border-radius: 4px;
      background-color: #003399;
      padding: 8px 10px;
      color: #fff;
      margin-right: 10px;
      font-family: Arial;
      font-size: 13px;
      font-weight: bold;
      line-height: 20px;
      position: relative;
      top: 0;
    }
  }
  ul {
    display: flex;
    flex-direction: column;
  }
  li {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    position: relative;
    height: 70px;
    white-space: nowrap;
  }
  li:not(:last-child) {
    border-bottom: 1px solid #DFEAFF;
  }

  .prefix-country {
    height: 15px;
    width: 71px;
    color: #002164;
    font-family: $EMSemi;
    font-size: 13px;
    line-height: 15px;
  }
}
